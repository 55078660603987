import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useUserStore from "../../../services/userStore";

const AuditLogs = () => {
  const userEmail = useUserStore((state) => state.user.email);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const [devicesList, setDevicesList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTenantsData = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/get-all-domains?email=${userEmail}`
      );
      setTenantsList(response.data);
    } catch (error) {
      console.log(error, "set tenants lsit error");
    }
  };

  const fetchDevices = async (tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      setDevicesList(response.data);
    } catch (error) {
      console.log(error, "set devices lsit error");
    }
  };

  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        const res = await axios.get(`${NGROK}/api/audit-logs`);
        setAuditLogs(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchAuditLogs ");
        setLoading(false);
      }
    };

    fetchAuditLogs();

    const interval = setInterval(() => {
      fetchAuditLogs();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchTenantsData();
  }, [userEmail]);

  useEffect(() => {
    setSelectedTenant(tenantsList[0]);
  }, [tenantsList]);

  useEffect(() => {
    if (selectedTenant) fetchDevices(selectedTenant.tenantName);
  }, [selectedTenant]);

  const deviceAuditLogs = auditLogs?.filter((log) => {
    const filteredLogs =
      log.tenantName === selectedTenant?.tenantName &&
      log.computerName === selectedDevice?.dNSHostName;
    if (selectedDevice) return filteredLogs;
    else return auditLogs;
  });

  if (loading) return <CircularProgress />;

  console.log(selectedTenant, "selectedTenant")
  console.log(selectedDevice,"selectedDevice" )

  return (
    <Stack spacing={4}>
      <Stack spacing={2} direction={"row"}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenant ? selectedTenant.tenantName : null}
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedTenant(null);
            }
            const tenant = tenantsList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setSelectedTenant(tenant);
            }
          }}
        />
        <Autocomplete
          disablePortal
          value={selectedDevice ? selectedDevice.dNSHostName : "All devices"}
          options={[
            "All devices",
            ...devicesList.map((device) => device.dNSHostName),
          ]}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Device List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedDevice(null);
            } else if (value === "All devices") {
              setSelectedDevice(null);
              return;
            }
            const device = devicesList.find(
              (device) => device.dNSHostName === value
            );
            if (device !== undefined) {
              setSelectedDevice(device);
            }
          }}
        />
      </Stack>

      {deviceAuditLogs.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflow: "hidden",
            display: "flex",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
              width: "fit-content",
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer User
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Type
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceAuditLogs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.time}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerUserEmail}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.resourceType}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.resourceName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.action}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : selectedTenant ? (
        <Typography fontSize={16} p={5}>
          There are no logs related to selected Tenant or Device
        </Typography>
      ) : (
        <Typography fontSize={16} p={5}>
          Select Tenant
        </Typography>
      )}
    </Stack>
  );
};

export default AuditLogs;
