import React, { useRef, useState } from "react";
import axios from "axios";

import moment from "moment/moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
  Typography,
  Stack,
  TextareaAutosize,
} from "@mui/material";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import styled from "styled-components";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";

const ResourceRequestForm = ({
  resourceName,
  selectedUser,
  resourceId,
  resourceType ,
  computer,
  sessionRequest,
  tenantName,
  publisherId
}) => {
  const [open, setOpen] = useState(false);
  const [period, setPeriod] = useState("");
  const messageRef = useRef(null);
  const [justification, setJustification] = useState("");

  const userData = useUserStore((state) => state.user);

  const [accessType, setAccessType] = useState("DYNAMIC");
  const [selectedTime, setSelectedTime] = useState(0);
  const [endDate, setEndDate] = useState(moment().add(1, "months").toDate());
  const [endTime, setEndTime] = useState(moment().add(1, "hours").toDate());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleSubmit = async () => {
    const date = moment(endDate);
    const time = moment(endTime);
    const combinedDateTime = date.set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    });

    const requestMessage = messageRef.current.value;
    setPeriod(combinedDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS"));

    const username = getStringBeforeAtSymbol(userData.email);

    const data = {
      userId: sessionRequest ? userData.userId : selectedUser.id,
      username: sessionRequest ? username : selectedUser?.cn,
      email: sessionRequest ? userData.email : selectedUser?.email,
      resourceType : sessionRequest ? "GROUP" : resourceType,
      resourceId: sessionRequest ? 57 : resourceId,
      resourceName,
      computerName: computer?.dNSHostName,
      requestMessage,
      requestStatus: "PENDING",
      selectedTime: selectedTime,
      computerId: computer.id,
      createdAt: new Date().toISOString(),
      privilegeLevel: accessType,
      tenantName,
      publisherId
    };

    await axios.post(`${NGROK}/api/access/create`, data);
    setOpen(false);
  };

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    () => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 80px;
    font-size: 16px;

  
    &:hover {
      border-color: ${blue[400]};
    }

  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Request access
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "18px" }}>
          Access request to {resourceName}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <FormControl component="fieldset">
              <Typography variant="h6">Access Type:</Typography>
              <RadioGroup
                aria-label="access-type"
                name="access-type"
                value={accessType}
                onChange={(e) => setAccessType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="PERMANENT"
                  control={<Radio />}
                  label="Permanent"
                />
                <FormControlLabel
                  value="DYNAMIC"
                  control={<Radio />}
                  label="Dynamic"
                />
              </RadioGroup>
            </FormControl>

            <Typography variant="h6">Computer Name: {computer?.dNSHostName}</Typography>

            <Stack spacing={2} mt={3}>
              <Typography variant="h6">Access justification:</Typography>
              <StyledTextarea
                disabled={sessionRequest}
                ref={messageRef}
              />
            </Stack>
            {accessType === "DYNAMIC" ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={!!selectedTime}
                    label="Access End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <TextField
                  disabled={!!selectedTime}
                  label="Access End Time"
                  type="time"
                  value={moment(endTime).format("HH:mm")}
                  onChange={(event) =>
                    setEndTime(moment(event.target.value, "HH:mm").toDate())
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                />
                <Stack spacing={2}>
                  <Typography align="center" variant="subtitle1">
                    OR
                  </Typography>
                  <TextField
                    value={selectedTime}
                    type="number"
                    fullWidth
                    size="small"
                    label={"Enter time in minutes"}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  />
                </Stack>
              </>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResourceRequestForm;
