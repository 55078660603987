import React, { useState } from "react";
import axios from "axios";

import { NGROK } from "../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import useUserStore from "../../services/userStore";
import RadioAuthFilter from "../components/RadioAuthFilter";

const ProfilePrivilegeManagement = ({
  userData,
  groups,
  setGroups,
  getProfileGroups,
  selectedProfileId,
  showCount,
  setShowCount,
  setGroupsSearchValues,
  groupsSearchValues,
}) => {
  const tenantName = useUserStore((state) => state.tenantName);
  const [filterGroupsValue, setFilterGroupsValue] = useState("Show All");

  const findGroup = (e) => {
    setTimeout(() => {
      setGroupsSearchValues(e.target.value);
    }, 1500);
  };

  const changeGroupStatus = async (group, groupStatus) => {
    const storageKey = `${tenantName}/profiles/${selectedProfileId}/membership-30min`;

    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
        ? "BLOCKED"
        : "DYNAMIC";
    setGroups(
      groups.map((el) =>
        el.id === group.id ? { ...group, profileResource } : el
      )
    );
    try {
      await axios.put(`${NGROK}/api/profiles/groups/update`, {
        email: userData.email,
        groupId: group.groupId,
        groupStatus,
        profileId: group.profileId,
      });
    } catch (error) {
      setGroups(groups.map((el) => (el.id === group.id ? group : el)));
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterGroupsValue(e.target.value);
  };

  const filteredGroups =
    groups?.length &&
    groups
      .filter((group) => {
        if (groupsSearchValues === "") {
          return group;
        } else if (
          group.cn.toLowerCase().includes(groupsSearchValues.toLowerCase())
        ) {
          return group;
        }
      })
      .filter((group) => {
        if (filterGroupsValue === "Show All") return group;

        if (filterGroupsValue === "Show Yes only")
          return group.profileResource === "ALLOWED";
        else if (filterGroupsValue === "Show No only")
          return group.profileResource === "BLOCKED";
        else if (filterGroupsValue === "Show Dynamic only")
          return group.profileResource === "DYNAMIC";
      });

  const getInheritedGroupStatusValue = (group) => {
    if (group.profileResource === "INHERITED") {
      return `(${group.trustLevel})`;
    } 
  };
  console.log(groups, "groupsList");

  return (
    <>
      {groups && groups.length > 0 ? (
        <TableContainer component={Paper} sx={{ maxWidth: "70%" }}>
          <Stack direction={"column"} spacing={2} sx={{ padding: "15px" }}>
            <RadioAuthFilter
              handleFilterResourceValue={handleFilterResourceValue}
              filterResourceValue={filterGroupsValue}
            />
            {groups?.length > 10 ? (
              <TextField
                fullWidth
                sx={{ minWidth: "200px" }}
                label="Search the Groups..."
                id="searchForGroup"
                onChange={findGroup}
              />
            ) : null}
          </Stack>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              minWidth: "fit-content",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Groups</TableCell>
                <TableCell className="userName">Membership</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups?.slice(0, showCount).map((group) => (
                <TableRow
                  className="groupRow"
                  key={`${group.id}${group.groupId}${group.cn}`}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    title={group.cn}
                  >
                    {group.cn}
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ minWidth: "100px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        group.profileResource === "ALLOWED"
                          ? "ENABLED"
                          : group.profileResource === "BLOCKED"
                          ? "DISABLED"
                          : group.profileResource === "DYNAMIC"
                          ? "DYNAMIC"
                          : "INHERITED"
                      }
                      size="small"
                      onChange={(event) =>
                        changeGroupStatus(group, event.target.value)
                      }
                    >
                      <MenuItem value={"ENABLED"}>YES</MenuItem>
                      <MenuItem value={"DISABLED"}>NO</MenuItem>
                      <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                      <MenuItem value={"INHERITED"}>INHERITED {getInheritedGroupStatusValue(group)}</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              {groups?.length > showCount ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : groups === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any groups.
          </p>
        </Box>
      )}
    </>
  );
};

export default ProfilePrivilegeManagement;
