import React, { useState } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./pages.scss";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import useUserStore from "../../services/userStore";
import RadioAuthFilter from "../components/RadioAuthFilter";

const ProfileDataAcces = ({
  folders,
  setFolders,
  getFolders,
  selectedProfileId,
  userData,
  showCount,
  setShowCount,
  foldersSearchValues,
  setFoldersSearchValues,
}) => {
  const tenantName = useUserStore((state) => state.tenantName);
  const [filterFoldersValue, setFilterFoldersValue] = useState("Show All");

  const findFolder = (e) => {
    setTimeout(() => {
      setFoldersSearchValues(e.target.value);
    }, 1500);
  };

  const changeFolderStatus = async (folder, groupStatus) => {
    const storageKey = `${tenantName}/profiles/${selectedProfileId}/membership-30min`;
    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
        ? "BLOCKED"
        : "DYNAMIC";
    setFolders(
      folders.map((el) =>
        el.id === folder.id ? { ...folder, profileResource } : el
      )
    );

    try {
      await axios.put(`${NGROK}/api/profiles/folders/update`, {
        email: userData.email,
        folderId: folder.folderId,
        groupStatus,
        profileId: folder.profileId,
      });
    } catch (error) {
      setFolders(folders.map((el) => (el.id === folder.id ? folder : el)));
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFoldersValue(e.target.value);
  };

  const filteredFolders =
    folders?.length &&
    folders
      .filter((folder) => {
        if (foldersSearchValues === "") {
          return folder;
        } else if (
          folder.path.toLowerCase().includes(foldersSearchValues.toLowerCase())
        ) {
          return folder;
        }
      })
      .filter((folder) => {
        if (filterFoldersValue === "Show All") return folder;

        if (filterFoldersValue === "Show Yes only")
          return folder.profileResource === "ALLOWED";
        else if (filterFoldersValue === "Show No only")
          return folder.profileResource === "BLOCKED";
        else if (filterFoldersValue === "Show Dynamic only")
          return folder.profileResource === "DYNAMIC";
      });

  const getInheritedFolderStatusValue = (folder) => {
    if (folder.profileResource === "INHERITED") {
      return `(${folder.trustLevel})`;
    }
  };

  console.log(folders, "folders");

  return (
    <>
      {folders && folders.length ? (
        <TableContainer component={Paper} sx={{ maxWidth: "70%" }}>
          <Stack direction={"column"} spacing={2} sx={{ padding: "15px" }}>
            <RadioAuthFilter
              handleFilterResourceValue={handleFilterResourceValue}
              filterResourceValue={filterFoldersValue}
            />
            {folders?.length > 10 ? (
              <TextField
                fullWidth
                sx={{ minWidth: "200px" }}
                label="Search the Groups..."
                id="searchForGroup"
                onChange={findFolder}
              />
            ) : null}
          </Stack>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Folders</TableCell>
                <TableCell className="userName">Trust level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filteredFolders?.slice(0, showCount).map((folder) => (
                  <TableRow
                    className="groupRow"
                    key={`${folder.id}${folder.folderName}${folder.folderId}`}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      className="groupRowName userName"
                      title={folder.path}
                    >
                      {folder.path}
                    </TableCell>
                    <TableCell>
                      <Select
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          folder.profileResource === "ALLOWED"
                            ? "ENABLED"
                            : folder.profileResource === "BLOCKED"
                            ? "DISABLED"
                            : folder.profileResource === "DYNAMIC"
                            ? "DYNAMIC"
                            : folder.profileResource === "INHERITED"
                            ? "INHERITED"
                            : null
                        }
                        size="small"
                        onChange={(event) =>
                          changeFolderStatus(folder, event.target.value)
                        }
                      >
                        <MenuItem value={"ENABLED"}>YES</MenuItem>
                        <MenuItem value={"DISABLED"}>NO</MenuItem>
                        <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                        <MenuItem value={"INHERITED"}>
                          INHERITED {getInheritedFolderStatusValue(folder)}
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
                {folders?.length > showCount ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Button
                        variant={"text"}
                        onClick={() =>
                          setShowCount((prevCount) => prevCount + 50)
                        }
                      >
                        Load more...
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </>
  );
};

export default ProfileDataAcces;
