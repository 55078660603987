import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ComputerIcon from "@mui/icons-material/Computer";
import useUserStore from "../../../services/userStore";
import SnackBarButton from "../SnackBarButton";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ThirdStep = ({ serverData }) => {
  const [users, setUsers] = useState();
  const [userGroups, setUserGroups] = useState();
  const [computers, setComputers] = useState();
  const tenantName = useUserStore((state) => state.tenantName);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${NGROK}/api/${tenantName}/computers`).then((res) => {
      console.log("res.data computers", res.data);
      setComputers(res.data);
    });

    // axios.get(`${NGROK}/api/${tenantName}/users`).then((res) => {
    //   console.log("res users", res.data);
    //   setUsers(res.data);
    // });
    // axios.get(`${NGROK}/api/${tenantName}/user-groups/membership`).then((res) => {
    //   console.log("res.data groups", res.data);
    //   setUserGroups(res.data);
    // });
  }, []);

  return (
    <section className="finaleShow">
      <div className="finaleSides">
        <h2>Active Directory Connection Settings</h2>
        <p>
          <span>Tenant Name :</span> {serverData.tenantName}
        </p>
        <p>
          <span>Domain Name :</span> {serverData.domainName}
        </p>
        <p>
          <span>LDAP Server :</span> {serverData.ldapServer}
        </p>
        <p>
          <span>Port :</span> {serverData.port}
        </p>
        <p>
          <span>Search Base :</span> {serverData.searchBase}
        </p>
        <p>
          <span>LDAP Server :</span> {serverData.server}
        </p>
        <p>
          <span>Bind DN :</span> {serverData.bindDN}
        </p>
        <Stack
          direction={"row"}
          spacing={5}
          display={"flex"}
          alignItems={"center"}
        >
          <p>API Key :</p>
          <SnackBarButton sx={{ height: "30px" }} size={"medium"} />
          <Button
            onClick={() => {
              navigate("/account");
            }}
            variant={"text"}
          >
            Navigate to Account
          </Button>
        </Stack>
        <div className="countsForDomain">
          <div>
            <PersonIcon /> Users : 0
          </div>

          {userGroups && (
            <div>
              <GroupIcon /> UserGroups : {userGroups.length}
            </div>
          )}

          <div>
            <ComputerIcon /> Computers : 0
          </div>
        </div>
        {/* <Button className="test_conection_btn" variant="contained">
          Test Connection
        </Button> */}
      </div>
      <div className="finaleSides">
        <h2>Additional Settings</h2>
        <Typography className="subHeading">- Scan</Typography>
        <p>
          <span>Domain User :</span> {serverData.domainUser}
        </p>
        <p>
          <span>Policy :</span> {serverData.policy}
        </p>
        <Typography className="subHeading">- Protect</Typography>
        <p>
          <span>Domain User :</span> {serverData.domainUserProtect}
        </p>
        <p>
          <span>Policy :</span> {serverData.domainPolicyProtect}
        </p>
      </div>
    </section>
  );
};

export default ThirdStep;
