import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components/macro";
import Snackbar from "@mui/material/Snackbar";

import { NGROK } from "../../APIs";

import axios from "axios";

import DomainTable from "../components/domainTable/DomainTable";
import CodeBlockInfo from "../components/domainTable/CodeBlockInfo";
import DomainInfoForm from "../components/domainTable/DomainInfoForm";
import ThirdStep from "../components/domainTable/ThirdStep";

import "./pages.scss";
import useUserStore from "../../services/userStore";

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 90%;
`;

const ButtonContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonLink = styled(Link)`
  margin-right: 10px;
  border: 1px solid rgba(55, 111, 208, 0.5);
  color: #376fd0;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 4px;
`;

const GetStart = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [testConnection, setTestConnection] = useState(false);
  const userData = useUserStore((state) => state.user);

  const setApiKey = useUserStore((state) => state.setApiKey);
  const tenantName = useUserStore(state => state.tenantName)
  const setTenantName = useUserStore((state) => state.setTenantName);
  const [serverData, setServerData] = useState({
    tenantName: "",
    domainName: "",
    ldapServer: "",
    port: "",
    searchBase: "",
    server: "",
    bindDN: "",
    bindPassword: "",
    domainUser: "",
    domainPassword: "",
    policy: "",
    domainUserProtect: "",
    domainPasswordProtect: "",
    domainPolicyProtect: "",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const hendleSave = () => {
    setTenantName(serverData.tenantName)

    axios
      .post(`${NGROK}/api/domain-info`, {
        ...serverData,
        email: userData.email,
      })
      .then((res) => {
        setApiKey(res.data.key)

        localStorage.removeItem(`get-all-domains?email=${userData.email}-30min`)
      })
  };
    
  const checkValidationPass = () => {
    if (serverData.domainName.length < 6) {
      setOpenSnackbar(true);
    } else {
      hendleSave();
      handleNext();
    }
  };

  const steps = [
    {
      label: "Setup your Service account in Active Directory",
      description: `
          You can create a gMSA only if the forest schema has been updated to
          Windows Server 2012, the master root key for Active Directory has been
          deloyed, and there is at least one Windows Server 2012 DC in the
          domain in which the gMSA will be created.
          Membership in Domain Admins or the ability to create msDS-Group
          Managed Service Account objects, is the minimum required to complete
          the following procedures.
        `,
      component: <CodeBlockInfo />,
    },
    {
      label: "Add Domain Information",
      description: "",
      component: (
        <DomainInfoForm
          setServerData={setServerData}
          serverData={serverData}
          testConnection={testConnection}
          setTestConnection={setTestConnection}
        />
      ),
    },
    {
      label: "Fleet Discovery",
      description: ``,
      component: <ThirdStep serverData={serverData} />,
    },
  ];

  return (
    <Container>
      <StyledBox>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                className="stepLabelBlock"
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <>{step.component}</>
                <Box sx={{ mb: 2 }}>
                  <div className="stepButtonsContainer">
                    {index === 0 ? null : (
                      <Button
                        variant="outlined"
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    )}
                    {index === 1 ? null : (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}

                    {index === 1 ? (
                      <>
                        <Button
                          disabled={!testConnection}
                          variant="contained"
                          sx={{ mt: 1, mr: 1 }}
                          onClick={checkValidationPass}
                        >
                          Save
                        </Button>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          open={openSnackbar}
                          autoHideDuration={5000}
                          onClose={handleCloseSnackbar}
                          message="Please fill up Domain name to save and go to the next step..."
                        />
                      </>
                    ) : null}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>

            <DomainTable serverData={serverData} />

            <ButtonContainerFooter>
              <ButtonLink to="/dashboard/default" variant="outlined">
                Dashboard
              </ButtonLink>
              <Button
                variant="outlined"
                onClick={handleReset}
                sx={{ mt: 1, mr: 1 }}
              >
                Add Domain
              </Button>
            </ButtonContainerFooter>
          </Paper>
        )}
      </StyledBox>
    </Container>
  );
};

export default GetStart;
