import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
  Box,
} from "@mui/material";

const MatchedResourceTable = ({
  matchedResources,
  resourceNameField,
  setManualExclusions,
  manualExclusions,
}) => {
  const handleAddExclusions = (resource) => {
    setManualExclusions((prev) => [
      ...prev,
      { id: resource.id, name: resource[resourceNameField] },
    ]);
  };

  const handleRemoveExclusions = (resource) => {
    const updatedExclusion = manualExclusions.filter(
      (exclusion) => exclusion.id !== resource.id
    );
    setManualExclusions(updatedExclusion);
  };

  const checkIfResourceInExclusions = (resource) => {
    return manualExclusions?.some((exclusion) => resource.id === exclusion.id);
  };

  console.log(manualExclusions, "manual exclusions");
  return (
    <Box>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 70 }} align="left">
              Sl No
            </TableCell>
            <TableCell align="left">Resource</TableCell>
            <TableCell align="left">Exclusions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchedResources.map((resource, index) => (
            <TableRow
              key={`${resource.name}  ${index}`}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "& > *": {
                  color: checkIfResourceInExclusions(resource)
                    ? "text.disabled"
                    : "inherit",
                },
              }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "260px",
                }}
                align="left"
              >
                {resource[resourceNameField]}
              </TableCell>
              <TableCell>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    disabled={checkIfResourceInExclusions(resource)}
                    onClick={() => handleAddExclusions(resource)}
                    variant="outlined"
                  >
                    Add
                  </Button>
                  <Button
                    disabled={!checkIfResourceInExclusions(resource)}
                    onClick={() => handleRemoveExclusions(resource)}
                    variant="outlined"
                  >
                    Remove
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MatchedResourceTable;
