import React from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Timer from "..//../components/Timer";

import ".//../pages.scss";
import { CircularProgress, MenuItem, Select, Table } from "@mui/material";
import { useState } from "react";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import useUserStore from "../../../services/userStore";
import ResourceRequestForm from "../../components/ResourceRequestForm";

const DataAccess = ({
  folders,
  setFolders,
  selectedTenantName,
  showCount,
  setShowCount,
  selectedUser,
  setFoldersSearchValues,
  foldersSearchValues,
}) => {
  const resourcesDefaultTime = useUserStore(
    (state) => state.resourcesDefaultTime
  );
  const activeComputer = useUserStore((state) => state.activeComputer);
  const userData = useUserStore((state) => state.user);

  const [filterFolderValue, setFilterFolderValue] = useState("Show All");
  const [activeTimerResource, setActiveTimerResource] = useState(null);
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackbarSettings;

  const findFolder = (e) => {
    setTimeout(() => {
      setFoldersSearchValues(e.target.value);
    }, 1500);
  };

  const changeFolderStatus = (newFolder) => {
    const updatedFolders = folders.map((folder) =>
      folder.folderId === newFolder.folderId ? newFolder : folder
    );

    setFolders(updatedFolders);
  };

  const handleSelectTime = (folder, event) => {
    if (event.key === "Enter") {
      addFolderToUser(folder, "DYNAMIC", event.target.value);
      setActiveTimerResource(null);
    }
  };

  const addFolderToUser = async (folder, groupStatus, inputTime) => {
    const profileName = activeComputer.profile;
    const timeInSeconds = (inputTime || resourcesDefaultTime[profileName]) * 60;

    changeFolderStatus({ ...folder, groupStatus });
    try {
      const response = await axios.put(
        `${NGROK}/api/${selectedTenantName}/computer-user/folders/timer`,
        {
          userId: folder.computerUserId,
          folderId: folder.folderId,
          email: userData.email,
          role: userData.role,
          groupStatus,
          selectedTime: timeInSeconds,
        }
      );
      if (
        groupStatus === "INHERITED" &&
        response.data.inheritedGroupStatus === null
      ) {
        setSnackbarSettings((prev) => ({ ...prev, open: true }));
        setTimeout(() => {
          setSnackbarSettings((prev) => ({ ...prev, open: false }));
        }, 4000);
      }
      changeFolderStatus(response.data);
    } catch (error) {
      changeFolderStatus(folder);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFolderValue(e.target.value);
  };

  const filteredFolders =
    folders?.length &&
    folders
      .filter((folder) => {
        if (foldersSearchValues === "") {
          return folder;
        } else if (
          folder.path.toLowerCase().includes(foldersSearchValues.toLowerCase())
        ) {
          return folder;
        }
      })
      .filter((folder) => {
        if (filterFolderValue === "Show All") return folder;
        else if (filterFolderValue === "Show Yes only")
          return (
            folder.groupStatus === "ENABLED" ||
            folder.inheritedGroupStatus === "ENABLED"
          );
        else if (filterFolderValue === "Show No only")
          return (
            folder.groupStatus === "DISABLED" ||
            folder.inheritedGroupStatus === "DISABLED"
          );
        else if (filterFolderValue === "Show Dynamic only")
          return (
            folder.groupStatus === "DYNAMIC" ||
            folder.inheritedGroupStatus === "DYNAMIC"
          );
      });

  const getInheritedGroupStatusValue = (folder) => {
    if (folder.inheritedGroupStatus === "ENABLED") {
      return "(YES)";
    } else if (folder.inheritedGroupStatus === "DISABLED") {
      return "(NO)";
    } else if (folder.inheritedGroupStatus === "DYNAMIC") {
      return "(DYNAMIC)";
    } else {
      return "";
    }
  };

  console.log(folders, "folders");
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      {folders && folders.length ? (
        <TableContainer component={Paper} sx={{ height: "fit-content" }}>
          <Stack direction={"column"} spacing={2} sx={{ padding: "15px" }}>
            <RadioAuthFilter
              filterResourceValue={filterFolderValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            {folders?.length > 10 ? (
              <TextField
                fullWidth
                sx={{ minWidth: "200px" }}
                label="Search the Groups..."
                id="searchForGroup"
                onChange={findFolder}
              />
            ) : null}
          </Stack>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Folders</TableCell>
                <TableCell className="userName">Privilege Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFolders?.map((folder) => (
                <TableRow className="groupRow" key={`${folder.folderId}`}>
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    title={folder.path}
                  >
                    {folder.path}
                  </TableCell>
                  <TableCell>
                    <Stack direction={"row"} spacing={3}>
                      <Select
                        disabled={userData.role === "TENANT_USER"}
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={folder.groupStatus ? folder.groupStatus : ""}
                        size="small"
                        onChange={(event) =>
                          addFolderToUser(folder, event.target.value)
                        }
                      >
                        <MenuItem value={"ENABLED"}>YES</MenuItem>
                        <MenuItem value={"DISABLED"}>NO</MenuItem>
                        <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                        <MenuItem value={"INHERITED"}>
                          INHERITED {getInheritedGroupStatusValue(folder)}
                        </MenuItem>
                      </Select>
                      {folder.groupStatus === "DYNAMIC" &&
                      folder.remainingTime !== 0 ? (
                        <Stack spacing={1} sx={{ width: 150 }}>
                          {folder.folderId === activeTimerResource ? (
                            <TextField
                              title="Use Enter key to add time"
                              type="number"
                              defaultValue={0}
                              onKeyDown={(event, value) => {
                                handleSelectTime(folder, event);
                              }}
                              label="Select time in min."
                            />
                          ) : (
                            <Timer
                              handleClick={() =>
                                setActiveTimerResource(folder.folderId)
                              }
                              minutes={folder.remainingTime}
                            />
                          )}
                        </Stack>
                      ) : null}
                      {(folder.groupStatus === "DISABLED" ||
                        folder.inheritedGroupStatus === "DISABLED" ||
                        (folder.inheritedGroupStatus === "DYNAMIC" &&
                          folder.remainingTime === 0) ||
                        (folder.groupStatus === "DYNAMIC" &&
                          folder.remainingTime === 0)) &&
                      userData.role === "TENANT_USER" ? (
                        <ResourceRequestForm
                          tenantName={selectedTenantName}
                          selectedUser={selectedUser}
                          resourceId={folder.folderId}
                          resourceType={"FOLDER"}
                          computer={activeComputer}
                          resourceName={folder.path}
                        />
                      ) : null}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {folders?.length > showCount ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </>
  );
};

export default DataAccess;
