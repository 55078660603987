import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const ServerDebugLogs = () => {
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isLocationActive, setIsLocationActive] = useState(false);
  const [isLogLevelActive, setIsLogLevelActive] = useState(false);

  useEffect(() => {
    const fetchApplicationLogs = async () => {
      try {
        const res = await axios.get(`${NGROK}/api/application-logs`);
        setApplicationLogs(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchApplicationLogs ");
        setLoading(false);
      }
    };

    fetchApplicationLogs();

    const interval = setInterval(() => {
      fetchApplicationLogs();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => setIsLocationActive(!isLocationActive)}
              checked={isLocationActive}
            />
          }
          label="Toggle Location"
        />
        <FormControlLabel
          required
          control={
            <Checkbox
              onChange={() => setIsLogLevelActive(!isLogLevelActive)}
              checked={isLogLevelActive}
            />
          }
          label="Toggle Log Level"
        />
      </FormGroup>
      {applicationLogs.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflow: "hidden",
            display: "flex",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
              width: "fit-content",
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>
                {isLogLevelActive ? (
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Log Level
                  </TableCell>
                ) : null}
                {isLocationActive ? (
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Location
                  </TableCell>
                ) : null}
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Message
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicationLogs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.time}
                  </TableCell>
                  {isLogLevelActive ? (
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.logLevel}
                    </TableCell>
                  ) : null}
                  {isLocationActive ? (
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.location}
                    </TableCell>
                  ) : null}
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.message}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography fontSize={16} p={5}>
          Logs are empty
        </Typography>
      )}
    </>
  );
};

export default ServerDebugLogs;
