import React, { useState } from "react";
import axios from "axios";
import {
  Stack,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Chip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MatchedResourceTable from "./MatchedResourceTable";
import { NGROK } from "../../../../APIs";
import ProfilesList from "./ProfileList";
import useUserStore from "../../../../services/userStore";
import { getResourceNameField } from "../../../../services/Helpers";

const CreateCategoryDialog = ({
  allSelectedResources,
  resourceType,
  os,
  fetchCategories,
  selectedTenantName,
  setSelectedCategory
}) => {
  const email = useUserStore((state) => state.user.email);

  const [newCategory, setNewCategory] = useState("");
  const [categoryPriority, setCategoryPriority] = useState(0);
  const [matchedResources, setMatchedResources] = useState([]);
  const [patternInputText, setPatternInputText] = useState("");
  const [searchPatternList, setSearchPatternList] = useState([]);
  const [manualExclusions, setManualExclusions] = useState([]);
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState(false);
  const [profilesWithTrustLevels, setProfilesWithTrustLevels] = useState([]);

  const resourceNameField = getResourceNameField(resourceType);

  const checkMatches = (newPattern) => {
    const filteredResources = allSelectedResources.filter((resource) =>
      resource[resourceNameField].match(newPattern)
    );
    console.log(filteredResources, "filteredResources");
    setMatchedResources(filteredResources);
  };

  const handleDeletePattern = (id) => {
    const newPatternListFiels = searchPatternList.filter(
      (field) => field.id !== id
    );
    setSearchPatternList(newPatternListFiels);
  };

  const addPatternToCategory = (inputText) => {
    const updatedPatternList = [
      ...searchPatternList,
      { id: uuidv4(), text: inputText },
    ];
    setMatchedResources([]);
    setSearchPatternList(updatedPatternList);
    setPatternInputText("");
  };

  const createCategory = async () => {
    const profiles = profilesWithTrustLevels.map((profile) => ({
      name: profile.name,
      profileId: profile.id,
      trustLevel: profile.trustLevel,
    }));

    try {
      const response = await axios.post(`${NGROK}/api/category`, {
        name: newCategory,
        reputationScore: categoryPriority,
        searchPatterns: searchPatternList,
        os,
        resourceType: resourceType.toUpperCase(),
        profiles,
        email,
        manualExclusions,
      });
      setSelectedCategory(response.data);
    } catch (error) {
      console.log(error, "error during updating categories");
    }
  };

  const closeAndCleanDialog = () => {
    setIsCreateCategoryDialogOpen(false);
    setMatchedResources([]);
    setSearchPatternList([]);
    setPatternInputText("");
  };

  const handleSubmit = () => {
    // const allResources = getAllMatchedResources(searchPatternList);

    createCategory();

    setTimeout(() => {
      fetchCategories(os);
    }, 1000);
    closeAndCleanDialog();
  };

  // update profiles resource logic
  const getAllMatchedResources = (newPatterns) => {
    return allSelectedResources.filter((resource) =>
      newPatterns.some((pattern) =>
        resource[resourceNameField].match(pattern.text)
      )
    );
  };

  console.log(searchPatternList, "searchPatternList");
  console.log(profilesWithTrustLevels, "profilesWithTrustLevelsCREATE");
  console.log(matchedResources, "matchedResources");
  console.log(email, "email");

  return (
    <>
      <Button
        size="medium"
        key={uuidv4()}
        variant="contained"
        color="primary"
        onClick={() => {
          setIsCreateCategoryDialogOpen(true);
        }}
      >Create new category</Button>
      <Dialog
        open={isCreateCategoryDialogOpen}
        onClose={closeAndCleanDialog}
        fullWidth
      >
        <DialogContent sx={{ overflow: "hidden" }}>
          <Stack spacing={4}>
            <Box
              height={60}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <TextField
                required
                autoFocus
                id="name"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => setNewCategory(e.target.value)}
                sx={{ height: 45, width: "70%" }}
              />
              <TextField
                required
                autoFocus
                id="name"
                label="Priority"
                type="number"
                variant="outlined"
                value={categoryPriority}
                onChange={(e) => setCategoryPriority(Number(e.target.value))}
                sx={{ height: 45, width: "25%" }}
              />
            </Box>

            <ProfilesList
              os={os}
              selectedTenantName={selectedTenantName}
              profilesWithTrustLevels={profilesWithTrustLevels}
              setProfilesWithTrustLevels={setProfilesWithTrustLevels}
            />

            <Box component={"fieldset"} borderRadius={1}>
              <legend>Resource Name/Pattern</legend>
              {searchPatternList.length ? (
                <Box
                  display={"inline-flex"}
                  flexWrap={"wrap"}
                  borderRadius={1}
                  // sx={{ backgroundColor: "#e6f2ff" }}
                >
                  {searchPatternList.map((textField) => (
                    <Chip
                      sx={{ margin: "4px" }}
                      key={textField.id}
                      variant="outlined"
                      color="primary"
                      label={textField.text}
                      onDelete={() => handleDeletePattern(textField.id)}
                      onClick={() => checkMatches(textField.text)}
                    />
                  ))}
                  <Button
                    sx={{ height: 31, margin: "4px" }}
                    variant="outlined"
                    onClick={() =>
                      setMatchedResources(
                        getAllMatchedResources(searchPatternList)
                      )
                    }
                  >
                    All matches
                  </Button>
                </Box>
              ) : null}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <TextField
                  sx={{ marginRight: "10px" }}
                  fullWidth
                  margin="dense"
                  id="name"
                  label="Pattern"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={patternInputText}
                  onChange={(e) => {
                    checkMatches(e.target.value);
                    setPatternInputText(e.target.value);
                  }}
                />
                <Button
                  disabled={!patternInputText || !matchedResources.length}
                  variant="outlined"
                  size={"small"}
                  // sx={{ height: "30px",  }}
                  onClick={(e) => addPatternToCategory(patternInputText)}
                >
                  Add pattern
                </Button>
              </Box>
            </Box>

            {matchedResources.length ? (
              <Box
                component={"fieldset"}
                borderRadius={1}
                overflow={"auto"}
                maxHeight={"230px"}
              >
                <legend>Matched Resources</legend>
                <MatchedResourceTable
                  setManualExclusions={setManualExclusions}
                  manualExclusions={manualExclusions}
                  matchedResources={matchedResources}
                  resourceNameField={resourceNameField}
                />
              </Box>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeAndCleanDialog}>
            Cancel
          </Button>
          <Button
            disabled={!newCategory || !searchPatternList.length}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateCategoryDialog;
