import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import Paper from "@mui/material/Paper";
import { NGROK } from "../../../APIs";
import {
  sortOrderLinuxProfiles,
  sortOrderWindowsProfiles,
} from "../../../services/Helpers";

const CategoryItem = ({ resourceType, os }) => {
  const [allCategoriesOfSelectedResource, setAllCategoriesOfSelectedResource] =
    useState([]);

  const getCategories = async (resourceType) => {
    try {
      const response = await axios.get(`${NGROK}/api/category/${resourceType}`);
      return response.data;
    } catch {
      console.log("error during fetching categories");
    }
  };

  const updateCategory = async (category, profile, trustLevel) => {
    const { reputationScore, name, searchPatterns, profiles } = category;

    const updatedProfiles = profiles
      .map((prevProfile) =>
        prevProfile.name === profile.name
          ? { ...prevProfile, trustLevel }
          : prevProfile
      )
      .sort((a, b) => {
        if (os.toUpperCase() === "WINDOWS") {
          const aIndex = sortOrderWindowsProfiles.indexOf(a.name);
          const bIndex = sortOrderWindowsProfiles.indexOf(b.name);
          return aIndex - bIndex;
        } else if (os.toUpperCase() === "LINUX") {
          const aIndex = sortOrderLinuxProfiles.indexOf(a.name);
          const bIndex = sortOrderLinuxProfiles.indexOf(b.name);
          return aIndex - bIndex;
        }
      });

    try {
      const response = await axios.put(`${NGROK}/api/category`, {
        reputationScore: reputationScore,
        oldName: name,
        oldSearchPatterns: searchPatterns,
        os,
        resourceType: resourceType.toUpperCase(),
        profiles: updatedProfiles,
      });

      const updatedCategories = allCategoriesOfSelectedResource.map(
        (category) =>
          category.name === response.data.name ? response.data : category
      );

      setAllCategoriesOfSelectedResource(updatedCategories)

      console.log(response.data, "response data");
    } catch {
      console.log("error during updating category");
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      switch (resourceType) {
        case "App":
          const appsCategories = await getCategories("for-apps");
          setAllCategoriesOfSelectedResource(appsCategories);
          break;
        case "Folder":
          const foldersCategories = await getCategories("for-folders");
          setAllCategoriesOfSelectedResource(foldersCategories);
          break;
        case "Group":
          const groupsCategories = await getCategories("for-groups");
          setAllCategoriesOfSelectedResource(groupsCategories);
          break;
        case "Publisher":
          const publishersCategories = await getCategories("for-publishers");
          setAllCategoriesOfSelectedResource(publishersCategories);
          break;
        case "URLcontrol":
          const urlControlCategories = await getCategories("for-url-control");
          setAllCategoriesOfSelectedResource(urlControlCategories);
          break;
        default:
          console.log("default");
      }
    };
    fetchCategories();
  }, []);

  console.log(os, "os");

  console.log(
    allCategoriesOfSelectedResource,
    "allCategoriesOfSelectedResource"
  );
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 70 }}>Sl No</TableCell>
              <TableCell sx={{ maxWidth: 300 }}>Category</TableCell>
              <TableCell align="center">Trust level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCategoriesOfSelectedResource.length
              ? allCategoriesOfSelectedResource.map((category, i) => (
                  <TableRow
                    key={uuidv4()}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction={"row"} spacing={3}>
                        {category.profiles?.map((profile) => (
                          <Box
                            key={`${profile.profileId}${profile.name}`}
                            display={"flex"}
                            justifyContent={"space-between"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            height={"80px"}
                          >
                            <Typography variant={"body1"}>
                              {profile.name}
                            </Typography>
                            <FormControl sx={{ width: 110 }}>
                              <InputLabel htmlFor="demo-simple-select">
                                Trust level
                              </InputLabel>
                              <Select
                                name={profile.name}
                                value={profile.trustLevel || ""}
                                onChange={(e) => {
                                  updateCategory(
                                    category,
                                    profile,
                                    e.target.value
                                  );
                                }}
                                label="Trust level"
                                id="demo-simple-select"
                                sx={{ height: 45 }}
                              >
                                <MenuItem value={"ENABLED"}>Yes</MenuItem>
                                <MenuItem value={"DISABLED"}>No</MenuItem>
                                <MenuItem value={"DYNAMIC"}>Dynamic</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CategoryItem;
