import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Stack,
  TextField,
  Box,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { useLocation } from "react-router-dom";
import CategoryItem from "./CategoryItem";
import RadioOS from "../ResourceCategories/components/OperationSystemTab";

const Categories = () => {
  const location = useLocation();
  const userData = useUserStore((state) => state.user);

  const [selectedOS, setSelectedOS] = useState("WINDOWS");

  const { defaultTenant, defaultProfile } = location.state;

  const [selectedTenant, setSelectedTenant] = useState(defaultTenant);
  const [tenantsList, setTenantsList] = useState([]);

  const [selectedProfile, setSelectedProfile] = useState(defaultProfile);

  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTenantsData = async () => {
    const response = await axios.get(
      `${NGROK}/api/get-all-domains?email=${userData.email}`
    );
    setTenantsList(response.data);
  };


  useEffect(() => {
    fetchTenantsData();
  }, []);


  console.log(selectedProfile, "selectedProfile Main");
  return (
    <Stack spacing={3}>
      <Stack direction={"row"} spacing={3}>
        {tenantsList.length && selectedTenant ? (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedTenant.tenantName}
            options={tenantsList.map((tenant) => tenant.tenantName)}
            sx={{
              width: 300,
              maxHeight: 50,
              backgroundColor: "white",
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tenant List" />
            )}
            onChange={(e, value) => {
              if (!value) {
                setSelectedTenant(null);
              }
              const tenant = tenantsList.find(
                (tenant) => tenant.tenantName === value
              );
              if (tenant !== undefined) {
                setSelectedTenant(tenant);
              }
            }}
          />
        ) : null}
        <RadioOS selectedOS={selectedOS} setSelectedOS={setSelectedOS} />
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Applications" {...a11yProps(0)} />
            <Tab label="Folders" {...a11yProps(1)} />
            <Tab label="Privileges " {...a11yProps(2)} />
            <Tab label="Publishers" {...a11yProps(3)} />
            <Tab label="URL control" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <CategoryItem
            resourceType={"App"}
            selectedTenant={selectedTenant}
            os={selectedOS}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <CategoryItem
            resourceType={"Folder"}
            selectedTenant={selectedTenant}
            os={selectedOS}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <CategoryItem
            resourceType={"Group"}
            selectedTenant={selectedTenant}
            os={selectedOS}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3}>
          <CategoryItem
            resourceType={"Publisher"}
            selectedTenant={selectedTenant}
            os={selectedOS}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={4}>
          <CategoryItem
            resourceType={"URLcontrol"}
            selectedTenant={selectedTenant}
            os={selectedOS}
          />
        </CustomTabPanel>
      </Box>
    </Stack>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  console.log(index, "index tab")
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Categories;
