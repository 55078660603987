import Keycloak from "keycloak-js";

let local_kc;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const userToken = localStorage.getItem("token");
const sessionTime = localStorage.getItem("sessionTime");

const setUpKc = (urlIn) => {
  if (!userToken) {
    local_kc = new Keycloak({
      url: urlIn,
      realm: "Whiteswan_Tenants",
      clientId: "otp-auth",
    });
  } else {
    local_kc = new Keycloak({
      url: urlIn,
      realm: "Whiteswan_Tenants",
      clientId: "otp-auth",
      token: userToken,
    });
  }
};

const _kc = () => {
  return local_kc;
};

const initKeycloak = async (onAuthenticatedCallback) => {
  console.log(local_kc, "_kc()");
  const idToken = localStorage.getItem("idToken");
  try {
    local_kc
      .init({
        onLoad: idToken ? "check-sso" : "login-required",
      })
      .then((authenticated) => {
        if (authenticated) {
          console.log("user is Authenticated..!");
        }
        else if (!authenticated) {
          doLogin();
          console.log("user is not authenticated..!");
        }
        localStorage.setItem("userEmail", `${local_kc.tokenParsed.email}`);
        localStorage.setItem("token", `${local_kc.token}`);
        localStorage.setItem("refreshToken", `${local_kc.refreshToken}`);
        localStorage.setItem("idToken", `${local_kc.idToken}`);

        if (sessionTime)
          localStorage.setItem(
            "sessionTime",
            sessionTime > 30 ? 30 : sessionTime <= 0 ? sessionTime : 5
          );
        else if (!sessionTime) localStorage.setItem("sessionTime", 30);
        onAuthenticatedCallback();
      })
      .catch(console.error);
  } catch (error) {
    console.log(error);
  }
};

const doLogin = () => local_kc.login();

const doLogout = () => {
  const idToken = local_kc.idToken || localStorage.getItem("idToken");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("sessionTime");
  console.log("before logout");
  console.log(idToken, "idToken");

  local_kc.logout({ id_token_hint: idToken });
  console.log("after logout");
};

const getToken = () => local_kc.token;

const isLoggedIn = () => !!local_kc.token;

// const updateToken = (successCallback) =>
//   _kc().updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => local_kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => local_kc.hasRealmRole(role));

const UserService = {
  setUpKc,
  initKeycloak,
  //   doLogin,
  doLogout,
  isLoggedIn,
  //   updateToken,
  getToken,
  getUsername,
  hasRole,
  _kc,
};

export default UserService;
