import React from "react";
import axios from "axios";
import {
  Link,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { NGROK } from "../../APIs";

import { FaLinux, FaUbuntu, FaRedhat, FaWindows } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";

const Downloads = () => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box
        width={"70%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <Card
          sx={{ maxWidth: 375, minWidth: 350, height: "100%", padding: "5px" }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FaWindows color="#233044" size={100} />
            <Typography fontSize={20} variant="subtitle1">
              Windows
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Link
              display={"flex"}
              justifyContent={"center"}
              fontSize={15}
              pl={2}
              href={`${NGROK}/api/download`}
            >
              <FaWindows color="#233044" size={25} />
              <Typography variant="subtitle1" pl={2}>
                Download WhiteSwanSecurity file
              </Typography>
            </Link>
          </CardActions>
        </Card>
        <Card
          sx={{ maxWidth: 375, minWidth: 350, height: "100%", padding: "5px" }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FaLinux color="#233044" size={100} />
            <Typography fontSize={20} variant="subtitle1">
              Linux
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Link
              display={"flex"}
              justifyContent={"center"}
              p={2}
              href={`${NGROK}/api/download/ubuntu`}
            >
              <FaUbuntu color="#233044" size={25} />
              <Typography fontSize={14} variant="subtitle1" pl={2}>
                Download UBUNTU WhiteSwanSecurity file
              </Typography>
            </Link>
            <Link
              display={"flex"}
              justifyContent={"center"}
              pt={0}
              href={`${NGROK}/api/download/rhel`}
            >
              <FaRedhat color="#233044" size={25} />
              <Typography fontSize={14} variant="subtitle1" pl={2}>
                Download RHEL WhiteSwanSecurity file
              </Typography>
            </Link>
            <Link
              display={"flex"}
              justifyContent={"center"}
              pt={2}
              href={`${NGROK}/api/download/linux/sh`}
            >
              <IoSettings color="#233044" size={25} />
              <Typography fontSize={14} variant="subtitle1" pl={2}>
                Download LINUX/SH WhiteSwanSecurity file
              </Typography>
            </Link>
            <Link
              display={"flex"}
              justifyContent={"center"}
              pt={2}
              href={`${NGROK}/api/download/linux/tar`}
            >
              <IoSettings color="#233044" size={25} />
              <Typography fontSize={14} variant="subtitle1" pl={2}>
                Download LINUX/TAR WhiteSwanSecurity file
              </Typography>
            </Link>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};

export default Downloads;
