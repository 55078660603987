import {
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Layout,
  List,
  Sliders,
  Users,
  User,
  Settings,
  Italic,
  Mail,
  FileText,
  BookOpen,
  Info,
} from "react-feather";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PaymentIcon from "@mui/icons-material/Payment";
import DownloadIcon from "@mui/icons-material/Download";

// localStorage.setItem("userAccess", "TENANT_ADMIN");

// const docsSection = [
//   {
//     href: "/getstart",
//     icon: Layout,
//     title: "Getting Started",
//   },
// ];

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      // {
      //   href: "/dashboard/analytics",
      //   title: "Analytics",
      // },
    ],
  },
  {
    href: "/incidents",
    icon: Italic,
    title: "Incidents",
    children: [
      {
        href: "/auditLogs",
        title: "Device Audit Logs",
      },
      {
        href: "/deviceDebugLogs",
        title: "Device Debug Logs",
      },
      {
        href: "/serverAuditLogs",
        title: "Server Audit Logs",
      },
      {
        href: "/serverDebugLogs",
        title: "Server Debug Logs",
      },
    ],
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/policyProfiles",
    icon: Layout,
    title: "Policy Profiles",
  },
  {
    href: "/tenantManagement",
    icon: SupervisorAccountIcon,
    title: "Tenant Management",
  },
  {
    href: "/resourceCategories",
    icon: BookOpen,
    title: "Resource Categories",
  },
  {
    href: "/resourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/reports",
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/overProvisioning",
        title: "Over-provisioning",
      },
      {
        href: "/historical-data",
        title: "Historical-data",
        children: [
          {
            href: "/privilegeCreep",
            title: "Privilege creep",
          },
          {
            href: "/localUsers",
            title: "Local users",
          },
          {
            href: "/suspiciousAccounts",
            title: "Suspicious accounts",
          },
        ],
      },
    ],
  },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/pricing",
  //   icon: PaymentIcon,
  //   title: "Pricing",
  // },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

const usersPagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      // {
      //   href: "/dashboard/analytics",
      //   title: "Analytics",
      // },
    ],
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/resourceRequests_user",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

const sysAdminPageSection = [
  {
    href: "/tenantManagement",
    icon: <SupervisorAccountIcon fontSize="large" />,
    title: "Tenant Management",
  },
];

export const adminNavsItems = [
  {
    title: "",
    pages: [...pagesSection],
  },
];
export const sysAdminNavsItems = [
  {
    title: "",
    pages: [...pagesSection],
  },
];

export const userNavsItems = [
  {
    title: "",
    pages: usersPagesSection,
  },
];
