import React, { useState, useEffect } from "react";
import axios from "axios";

import styled from "styled-components/macro";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  userNavsItems,
  adminNavsItems,
  sysAdminNavsItems,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";

import useUserStore from "../services/userStore";
import BreadcrumbsNav from "../components/BreadcrumbsNav";
import { NGROK } from "../APIs";
import { useCallback } from "react";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userAccessMenu, setUserAccessMenu] = useState(null);
  const [deviceList, setDeviceList] = useState([])

  
  const location = useLocation();
  const setAdminNotifications = useUserStore(
    (state) => state.setAdminNotifications
  );
  const setUserNotifications = useUserStore(
    (state) => state.setUserNotifications
  );

  const userData = useUserStore((state) => state.user);

  let userAccess = userData.role;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (userAccess === "TENANT_ADMIN") {
      setUserAccessMenu(adminNavsItems);
    }
    if (userAccess === "SYS_ADMIN") {
      setUserAccessMenu(sysAdminNavsItems);
    }
    if (userAccess === "TENANT_USER") {
      setUserAccessMenu(userNavsItems);
    }
  }, [userAccess]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (userAccess === "TENANT_ADMIN" || userAccess === "SYS_ADMIN") {
      const response = await axios.get(
        `${NGROK}/api/access/admin-notifications`
      );
      setAdminNotifications(response.data);
      } else {
        const response = await axios.get(
        `${NGROK}/api/access/user-notifications?userId=${userData.userId}`
      );
      setUserNotifications(response.data);
      }
    } catch (error) {
      console.log(error, "error fetchNotifications");
    }
  }, [setAdminNotifications, setUserNotifications, userAccess, userData]);

  useEffect(() => {
      const interval = setInterval(() => {
        fetchNotifications();
      }, 5000);
      return () => clearInterval(interval);
  }, [fetchNotifications, userAccess]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={userAccessMenu}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={userAccessMenu}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 8 : 4}>
          {location.pathname === "/endpoints/resources" ||
          location.pathname === "/endpoints" ||
          location.pathname === "/endpoints/sessions" ? (
            <Box p={4}>
              <BreadcrumbsNav page={"endpoints"} />
            </Box>
          ) : location.pathname === "/policyProfiles/resources" ||
            location.pathname === "/policyProfiles" ? (
            <Box p={4}>
              <BreadcrumbsNav page={"policyProfiles"} />
            </Box>
          ) : null}
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
