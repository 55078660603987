import React, { useState } from "react";

import { Checkbox, FormControlLabel, Box, TextField } from "@mui/material";
import { Button, CircularProgress, Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

const DomainInfoForm = ({
  setServerData,
  serverData,
  testConnection,
  setTestConnection,
}) => {
  const [showMessage, setShowMessage] = useState("");
  const [isChecked, setisChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [domain, setDomain] = useState(null);


  const handleCheckbox = () => {
    if (!isChecked) {
      setServerData({ ...serverData, domainName: serverData.tenantName });
    } else {
      setServerData({ ...serverData, domainName: domain });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(serverData, "serverData");
  };

  const handleChange = (e) => {
    if (testConnection) setTestConnection(false)
    
    if (e.target.name === "tenantName" && isChecked) {
      setServerData({
        ...serverData,
        [e.target.name]: e.target.value,
        domainName: e.target.value,
      });
    } else {
      setServerData({ ...serverData, [e.target.name]: e.target.value });

      if (e.target.name === "domainName") setDomain(e.target.value);
    }
  };

  const checkDomainConnection = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${NGROK}/api/test-connection?tenantName=${serverData.tenantName}`
    );

    console.log(response.data, "response.daa");

    if (response.data) {
      setTimeout(() => {
        setShowMessage("success");
        setIsLoading(false);
        setTestConnection(response.data);
      }, 1500);
    }

    if (!response.data) {
      setTimeout(() => {
        setShowMessage("error");
        setIsLoading(false);
        setTestConnection(response.data);
      }, 1500);
    }

    setTimeout(() => {
      setShowMessage("");
    }, 3500);
  };

  return (
    <section className="secondStep">
      <form className="styledForm" autoComplete="off" onSubmit={handleSubmit}>
        <div className="formSides">
          <h2 className="heading">Active Directory Connection Settings</h2>
          <TextField
            required={true}
            fullWidth
            label="Tenant Name"
            id="tenantName"
            name="tenantName"
            placeholder="Tenant Name"
            defaultValue={serverData.tenantName}
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <Box
            display={"flex"}
            spacing={2}
            direction={"row"}
          >
            <TextField
              disabled={isChecked}
              required={true}
              sx={{ width: "70%", paddingRight: "10px" }}
              label="Domain Name"
              id="domainName"
              name="domainName"
              placeholder="Domain Name"
              defaultValue={serverData.domainName}
              size="small"
              onChange={(e) => handleChange(e)}
            />
            <FormControlLabel
              label={"Same as Tenant Name"}
              control={
                <Checkbox
                  onChange={() => {
                    setisChecked(!isChecked);
                  }}
                  onClick={handleCheckbox}
                  aria-label="Checkbox demo"
                  defaultChecked
                />
              }
            ></FormControlLabel>
          </Box>

          <div className="twoInputsServer">
            <TextField
              fullWidth
              label="LDAP Server"
              id="ldapServer"
              name="ldapServer"
              placeholder="Server"
              defaultValue={serverData.ldapServer}
              size="small"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              fullWidth
              label="Port"
              id="port"
              name="port"
              placeholder="Port"
              type="number"
              defaultValue={serverData.port}
              size="small"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <TextField
            fullWidth
            label="Search Base"
            id="searchBase"
            name="searchBase"
            placeholder="Search Base"
            defaultValue={serverData.searchBase}
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <TextField
            fullWidth
            label="LDAP Server"
            id="server"
            name="server"
            placeholder="Server"
            defaultValue={serverData.server}
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <div className="twoInputsServer">
            <TextField
              fullWidth
              label="Bind DN"
              id="bindDN"
              name="bindDN"
              placeholder="Bind DN"
              defaultValue={serverData.bindDN}
              size="small"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              fullWidth
              label="Bind Password"
              id="bindPassword"
              name="bindPassword"
              placeholder="Bind Password"
              type="password"
              defaultValue={serverData.bindPassword}
              size="small"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="checkDomainConnection">
            <Button
              onClick={checkDomainConnection}
              type="submit"
              className="test_conection_btn"
              variant="contained"
            >
              Test Connection
            </Button>
            <div className="checkAnimationBlocks">
              {isLoading && <CircularProgress />}

              {showMessage === "error" ? (
                <Alert severity="error" variant="filled">
                  <AlertTitle>Error</AlertTitle>Connection error — check data!
                </Alert>
              ) : null}
              {showMessage === "success" ? (
                <Alert severity="success" variant="filled">
                  <AlertTitle>Success</AlertTitle>Connection stable !
                </Alert>
              ) : null}
            </div>
          </div>
        </div>
        <div className="formSides">
          <h2 className="heading">Additional Settings</h2>
          <div className="sub_heading">
            <p>- Scan</p>
          </div>
          <div className="twoInputsServer">
            <TextField
              fullWidth
              label="Domain User"
              id="domainUser"
              name="domainUser"
              placeholder="Domain User"
              defaultValue={serverData.domainUser}
              size="small"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              fullWidth
              label="Domain Password"
              id="domainPassword"
              name="domainPassword"
              placeholder="Domain Password"
              type="password"
              defaultValue={serverData.domainPassword}
              size="small"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <TextField
            fullWidth
            label="Policy"
            id="policy"
            name="policy"
            placeholder="Policy"
            defaultValue={serverData.policy}
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <div className="sub_heading">
            <p>- Protect</p>
          </div>
          <div className="twoInputsServer">
            <TextField
              fullWidth
              label="Domain User"
              id="domainUserProtect"
              name="domainUserProtect"
              placeholder="Domain User"
              defaultValue={serverData.domainUserProtect}
              size="small"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              fullWidth
              label="Domain Password"
              id="domainPasswordProtect"
              name="domainPasswordProtect"
              placeholder="Domain Password"
              type="password"
              defaultValue={serverData.domainPasswordProtect}
              size="small"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <TextField
            fullWidth
            label="Policy"
            id="domainPolicyProtect"
            name="domainPolicyProtect"
            placeholder="Policy"
            defaultValue={serverData.domainPolicyProtect}
            size="small"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </form>
    </section>
  );
};

export default DomainInfoForm;
