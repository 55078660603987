import React from "react";
import { Navigate } from "react-router-dom";
import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";

// Page components

import Pricing from "./pages/pages/Pricing";
import Projects from "./pages/pages/Projects";
import AccessManagement from "./pages/pages/AccessManagement";
import GetStart from "./pages/pages/GetStart";

// Documentation
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import Endpoints from "./pages/pages/Endpoints";
import ProfileResources from "./pages/pages/ProfileResources";
import Account from "./pages/pages/Account";
import TenantManagement from "./pages/pages/TenantManagement";
import Downloads from "./pages/pages/Downloads";
import EndpointsResources from "./pages/pages/EndpointsResources/EndpointsResources";
import Profiles from "./pages/pages/Profiles";
import SessionsAndProcesses from "./pages/pages/SessionsAndProcesses";
import ResourceRequests from "./pages/pages/ResourceRequests";
import ResourceRequestsUser from "./pages/pages/ResourceRequestsUser";
import ServerAuditLogs from "./pages/pages/logs/ServerAuditLogs";
import AuditLogs from "./pages/pages/logs/AuditLogs";
import DeviceDebugLogs from "./pages/pages/logs/DeviceDebugLogs";
import ServerDebugLogs from "./pages/pages/logs/ServerDebugLogs";
import OverProvisioning from "./pages/pages/reports/OverProvisioning";
import PrivilegeCreep from "./pages/pages/reports/HistoricalData/PrivilegeCreep";
import LocalUsers from "./pages/pages/reports/HistoricalData/LocalUsers";
import SuspiciousAccounts from "./pages/pages/reports/HistoricalData/SuspiciousAccounts";
import Categories from "./pages/pages/Profiles/Categories";
import ResourceCategories from "./pages/pages/ResourceCategories/ResourceCategories";
import About from "./pages/pages/About";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));

// Icon components
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

const usersLoginStatus = localStorage.getItem("firstUserLogin");

setTimeout(() => {
  console.log("usersLoginStatus in routes", usersLoginStatus);
}, 1000);

const routes = [
  {
    // path: "/",
    // element: <Navigate to="/getstart" />,
    element: <DashboardLayout />,
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
    ],
  },
  {
    path: "policyProfiles",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Profiles />,
      },
      {
        path: "resources",
        element: <ProfileResources />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
    ],
  },
  {
    path: "pricing",
    element: <DashboardLayout children={<Pricing />} />,
  },
  {
    path: "management",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AccessManagement />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "tenantManagement",
    element: <DashboardLayout children={<TenantManagement />} />,
  },
  {
    path: "resourceRequests",
    element: <DashboardLayout children={<ResourceRequests />} />,
  },
    {
    path: "resourceCategories",
    element: <DashboardLayout children={<ResourceCategories />} />,
  },
  {
    path: "resourceRequests_user",
    element: <DashboardLayout children={<ResourceRequestsUser />} />,
  },
  {
    path: "serverAuditLogs",
    element: <DashboardLayout children={<ServerAuditLogs />} />,
  },
  {
    path: "auditLogs",
    element: <DashboardLayout children={<AuditLogs />} />,
  },
  {
    path: "deviceDebugLogs",
    element: <DashboardLayout children={<DeviceDebugLogs />} />,
  },
  {
    path: "serverDebugLogs",
    element: <DashboardLayout children={<ServerDebugLogs />} />,
  },
  {
    path: "overProvisioning",
    element: <DashboardLayout children={<OverProvisioning />} />,
  },
  {
    path: "privilegeCreep",
    element: <DashboardLayout children={<PrivilegeCreep />} />,
  },
  {
    path: "localUsers",
    element: <DashboardLayout children={<LocalUsers />} />,
  },
  {
    path: "suspiciousAccounts",
    element: <DashboardLayout children={<SuspiciousAccounts />} />,
  },
  {
    path: "endpoints",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Endpoints />,
      },
      {
        path: "sessions",
        element: <SessionsAndProcesses />,
      },
      {
        path: "resources",
        element: <EndpointsResources />,
      },
    ],
  },

  {
    path: "account",
    element: <DashboardLayout children={<Account />} />,
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "downloads",
    element: <DashboardLayout children={<Downloads />} />,
  },
  {
    path: "about",
    element: <DashboardLayout children={<About />} />,
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <DashboardLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "404",
    element: <DashboardLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
