import React, { useState } from "react";
import axios from "axios";
import { Timer } from "@mui/icons-material";
import {
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";

const UrlControlRow = ({
  urlControl,
  tenantName,
  userId,
  fetchUrlControlData,
}) => {
  const userData = useUserStore((state) => state.user);
  const [activeTimerResource, setActiveTimerResource] = useState(null);

  const handleSelectTime = (resource, event) => {
    if (event.key === "Enter") {
      //   addFolderToUser(folder, "DYNAMIC", event.target.value);
      setActiveTimerResource(null);
    }
  };

  const updateGroupsStatus = async (url, groupStatus) => {
    try {
      const response = await axios.put(
        `${NGROK}/api/${tenantName}/computer-user/url-control/timer`,
        {
          userId,
          urlId: url.urlId,
          groupStatus,
          selectedTime: 0,
          email: userData.email,
          role: userData.role,
        }
      );
      console.log(response.data, "response after updating status");
      fetchUrlControlData();
    } catch (error) {
      console.error(error, "failed to update groupStatus");
    }
  };

  const deleteUrl = async (urlId) => {
    try {
      const response = await axios.delete(`${NGROK}/api/url-control/${urlId}`);

      if (response.data === true) fetchUrlControlData();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <TableRow className="groupRow" key={`${urlControl.name}`}>
        <TableCell
          component="td"
          scope="row"
          className="groupRowName userName"
          title={urlControl.name}
        >
          {urlControl.name}
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing={3}>
            <Select
              disabled={userData.role === "TENANT_USER"}
              sx={{ minWidth: "100px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => updateGroupsStatus(urlControl, e.target.value)}
              value={urlControl.groupStatus}
              size="small"
            >
              <MenuItem value={"ENABLED"}>YES</MenuItem>
              <MenuItem value={"DISABLED"}>NO</MenuItem>
              <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
            </Select>
            {urlControl.groupStatus === "DYNAMIC" &&
            urlControl.remainingTime !== 0 ? (
              <Stack spacing={1} sx={{ width: 150 }}>
                {urlControl.id === activeTimerResource ? (
                  <TextField
                    title="Use Enter key to add time"
                    type="number"
                    defaultValue={0}
                    onKeyDown={(event, value) => {
                      handleSelectTime(urlControl, event);
                    }}
                    label="Select time in min."
                  />
                ) : (
                  <Timer
                    handleClick={() => setActiveTimerResource(urlControl.id)}
                    minutes={urlControl.remainingTime}
                  />
                )}
              </Stack>
            ) : null}
          </Stack>
        </TableCell>
        <TableCell
          component="td"
          scope="row"
          className="groupRowName userName"
          align="center"
          sx={{ width: "50px" }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => deleteUrl(urlControl.urlId)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UrlControlRow;
