import React, { useEffect, useState } from "react";
import { version as uiVersion } from "react-scripts/package.json";
import axios from "axios";
import { NGROK } from "../../APIs";
import { Stack, Typography } from "@mui/material";

const About = () => {
  const [backendVersion, setbackendVersion] = useState("");
  const [error, setError] = useState("");

  const getVersion = async () => {
    try {
      const response = await axios.get(`${NGROK}/api/build-version`);
      setbackendVersion(response.data);
      setError("");
      console.log(response.data, "version");
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    getVersion();
  }, []);

  if (error)
    return <div>Something went wrong during fetching version. {error}</div>;
  if (!error)
    return (
      <Stack spacing={3}>
        <Typography variant="h6">
          Whiteswan Security Inc. All rights reserved.
        </Typography>

        <Typography variant="h6">Frontend Version 4.0.9</Typography>
        <Typography variant="h6">Backend Version {backendVersion}</Typography>
        <Typography variant="h6">Agent Version 23.10.15</Typography>
      </Stack>
    );
};

export default About;
