import React, { useEffect, useState } from "react";

import { Button, Snackbar } from "@mui/material";

import MuiAlert from "@mui/material/Alert";

const SnackBarButton = ({
  apiKey,
  tenant,
  generateApiKey,
  selectedTenantName,
}) => {
  const [snackbarSettings, setSnackbarSettings] = useState({
    isCopySuccess: false,
    vertical: "top",
    horizontal: "left",
  });

  const { vertical, horizontal, isCopySuccess } = snackbarSettings;

  const copyToClipboard = (newSnackbarSettings) => () => {
    navigator.clipboard.writeText(apiKey ? apiKey : "random text");
    setSnackbarSettings({ isCopySuccess: true, ...newSnackbarSettings });
    setTimeout(() => {
      setSnackbarSettings({ isCopySuccess: false, ...newSnackbarSettings });
    }, 3000);
  };

  return (
    <>
      <Button
        onClick={
          apiKey
            ? copyToClipboard({
                vertical: "top",
                horizontal: "left",
              })
            : () => generateApiKey(tenant)
        }
        size={"medium"}
        variant="outlined"
        sx={
          tenant && tenant.tenantName === selectedTenantName
            ? { color: "white" }
            : {}
        }
      >
        {apiKey ? apiKey : "Generate API key"}
      </Button>
      <Snackbar
        color="blue"
        open={isCopySuccess}
        anchorOrigin={{ vertical, horizontal }}
        message="Copied!"
        key={vertical + horizontal}
      >
        <MuiAlert severity="success" sx={{ width: "100%" }}>
          Copied!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default SnackBarButton;
