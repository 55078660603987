import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";

import {
  Avatar,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,

} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";
import CustomSnackbar from "./CustomSnackbar";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function Account() {
  const userData = useUserStore((state) => state.user);

  const [accountInfo, setAccountInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const {
    id,
    firstName = "",
    lastName = "",
    email = "",
    emailForUpdate = "",
    city = "",
    apartment = "",
    state = "",
    zip = null,
    username = "",
    biography = "",
  } = accountInfo;

  const privateUpdateData = async () => {
    try {
      const response = await axios.put(
        `${NGROK}/api/account/${id}/settings/private-info`,
        {
          accountId: id,
          apartment,
          city,
          firstName,
          lastName,
          state,
          zip,
        }
      );
      return response;
    } catch (error) {
      console.error("Private API request failed:", error);
      throw error;
    }
  };

  const publicUpdateData = async () => {
    try {
      const isUserNameUnique = await axios.get(
        `${NGROK}/api/account/check-username?username=${username}`
      );
      if (isUserNameUnique) {
        const response = await axios.put(
          `${NGROK}/api/account/${id}/settings/public-info`,
          {
            accountId: id,
            biography,
            username,
          }
        );
        console.log("User", response);
        return response;
      }
    } catch (error) {
      console.log("user is not unique");
      throw error;
    }
  };

  const handleUpdateData = async () => {
    try {
      const isPublicUpdateData = await publicUpdateData();
      const isPrivateUpdateData = await privateUpdateData();
      if (isPublicUpdateData && isPrivateUpdateData) {
        setOpen(true);
        setSnackbarMessage("Saved");
        setSnackbarSeverity("success");
      }
    } catch (error) {
      setOpen(true);
      console.error("An error occurred:", error);
      setSnackbarMessage("Error: Something went wrong");
      setSnackbarSeverity("error");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${NGROK}/api/account?email=${userData.email}`
      );
      setAccountInfo(response.data);
      console.log(response.data);
    };
    fetchData();
  }, [userData]);

  return (
    <>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Account
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public
            userData={userData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <Private
            userData={userData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <CardContent>
            <Button
              onClick={handleUpdateData}
              variant="contained"
              color="primary"
            >
              Save changes
            </Button>
            <CustomSnackbar
              open={open}
              snackbarMessage={snackbarMessage}
              snackbarSeverity={snackbarSeverity}
              onClose={handleClose}
            />
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
}

function Public({ accountInfo, setAccountInfo, userData }) {
  const { username, biography, id } = accountInfo;

  console.log(accountInfo);
  const inputRef = useRef(null);
  const [image, setImage] = useState("");

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={8}>
            <TextField
              value={username || ""}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, username: e.target.value })
              }
              id="username"
              label="User Name"
              variant="outlined"
              fullWidth
              my={2}
            />

            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                value={biography || ""}
                onChange={(e) =>
                  setAccountInfo({ ...accountInfo, biography: e.target.value })
                }
                label="Biography"
                id="biography"
                multiline={true}
                rows={3}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <CenteredContent>
              {image ? (
                <BigAvatar src={URL.createObjectURL(image)} alt=" " />
              ) : (
                <BigAvatar
                  alt="Remy Sharp"
                  src="/static/img/avatars/avatar-1.jpg"
                />
              )}
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                ref={inputRef}
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} onClick={handleImageClick} /> Upload
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Private({ accountInfo, setAccountInfo }) {
  const { firstName, lastName, email, city, apartment, state, zip } =
    accountInfo;

  const handleChange = (e) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Private info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              value={firstName || ""}
              name={"firstName"}
              onChange={(e) => handleChange(e)}
              id="first-name"
              label="First Name"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              name={"lastName"}
              value={lastName || ""}
              onChange={(e) => handleChange(e)}
              id="last-name"
              label="Last Name"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <TextField
          name={"email"}
          value={email || ""}
          onChange={(e) => handleChange(e)}
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          my={2}
        />
        {/* 
        <TextField
          value={setp.username}
              onChange={(e) => setPublicInfo({...publicInfo, username: e.target.value})}
          id="address"
          label="Address"
          variant="outlined"
          fullWidth
          my={2}
        /> */}

        <TextField
          name={"apartment"}
          value={apartment || ""}
          onChange={(e) => handleChange(e)}
          id="address2"
          label="Apartment"
          variant="outlined"
          fullWidth
          my={2}
        />

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              name={"city"}
              value={city || ""}
              onChange={(e) => handleChange(e)}
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              name={"state"}
              value={state || ""}
              onChange={(e) => handleChange(e)}
              id="state"
              label="State"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              name={"zip"}
              value={zip || ""}
              onChange={(e) => handleChange(e)}
              id="zip"
              label="ZIP"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Account;
