import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Stack } from "@mui/material";
import { NGROK } from "../../../APIs";
import ResourcesTable from "./ResourcesTable";
import ResouceTypeTable from "./ResouceTypeTable";
import CategoriesTable from "./CategoriesTable";
import ".//../pages.scss";
import ExportImport from "./components/ExportImport";
import CreateCategoryDialog from "./components/CreateCategoryDialog";
import TenantSelection from "./components/TenantSelection";
import OperationSystemTab from "./components/OperationSystemTab";
import RadioFilter from "./components/RadioFilter";

const ResourceCategories = () => {
  const [osValue, setOsValue] = useState("WINDOWS");
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [allSelectedResources, setAllSelectedResources] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [filterResourceValue, setFilterResourceValue] =
    useState("Show selected");

  const [selectedResourceType, setSelectedResourceType] = useState({
    name: "App",
    id: 1,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);

  const getAllSelectedResources = async (resourceType, os) => {
    const response = await axios.get(
      `${NGROK}/api/computers/operation-system/${resourceType}?os=${os}`
    );
    return response.data;
  };

  const fetchResources = async (os) => {
    switch (selectedResourceType.name) {
      case "App":
        console.log("application type here");
        const apps = await getAllSelectedResources("get-all-apps", os);
        apps.content.sort((a, b) => a.id - b.id);
        setAllSelectedResources(apps.content);
        break;
      case "Folder":
        const folders = await getAllSelectedResources("get-all-folders", os);
        folders.content.sort((a, b) => a.id - b.id);
        setAllSelectedResources(folders.content);
        break;
      case "Group":
        const privileges = await getAllSelectedResources("get-all-groups", os);
        privileges.content.sort((a, b) => a.id - b.id);
        setAllSelectedResources(privileges.content);
        break;
      case "Publisher":
        const publishers = await getAllSelectedResources(
          "get-all-publishers",
          os
        );
        publishers.sort((a, b) => a.id - b.id);
        setAllSelectedResources(publishers);
        break;
      case "Url":
        const urls = await getAllSelectedResources("get-all-url-control", os);
        urls.content?.sort((a, b) => a.id - b.id);
        setAllSelectedResources(urls.content);
        break;
      default:
        console.log("default");
    }
  };

  const getCategories = async (resourceType) => {
    try {
      const response = await axios.get(`${NGROK}/api/category/${resourceType}`);
      return response.data;
    } catch {
      console.log("error during fetching categories");
    }
  };

  const fetchCategories = async (os) => {
    switch (selectedResourceType.name) {
      case "App":
        const appsCategories = await getCategories("for-apps");
        const appsCategoriesFilteredByOs = appsCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase()
        );
        setCategoryList(appsCategoriesFilteredByOs);

        break;
      case "Folder":
        const folderCategories = await getCategories("for-folders");
        const foldersCategoriesFilteredByOs = folderCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase()
        );
        setCategoryList(foldersCategoriesFilteredByOs);

        break;
      case "Group":
        const groupCategories = await getCategories("for-groups");
        const groupsCategoriesFilteredByOs = groupCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase()
        );
        setCategoryList(groupsCategoriesFilteredByOs);

        break;
      case "Publisher":
        const publisherCategories = await getCategories("for-publishers");
        const publisherCategoriesFilteredByOs = publisherCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase()
        );
        setCategoryList(publisherCategoriesFilteredByOs);

        break;
      case "Url":
        const urlControlCategories = await getCategories("for-url-control");
        const urlCategoriesFilteredByOs = urlControlCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase()
        );
        setCategoryList(urlCategoriesFilteredByOs);
        break;
      default:
        console.log("default");
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterResourceValue(e.target.value);
  };

  const categoriesTableProps = {
    setAllSelectedResources,
    fetchResources,
    os: osValue,
    allSelectedResources,
    selectedTenantName: selectedTenant?.tenantName,
    resourceType: selectedResourceType.name,
    selectedCategory,
    setSelectedCategory,
    fetchCategories,
    categoryList,
    setCategoryList,
  };

  const resourceTableProps = {
    fetchResources,
    allSelectedResources,
    setAllSelectedResources,
    resourceType: selectedResourceType.name,
    selectedCategory,
    os: osValue,
    handleFilterResourceValue,
    filterResourceValue,
  };

  useEffect(() => {
    setSelectedCategory(null);
  }, [selectedResourceType]);

  console.log(selectedCategory, "selected category");
  return (
    <Box width={"fit-content"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={10}
      >
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <Box display={"flex"} justifyContent={"space-between"} width={355}>
          <ExportImport
            os={osValue}
            fetchCategories={fetchCategories}
            resourceType={selectedResourceType.name}
            categoryList={categoryList}
          />
          <CreateCategoryDialog
            selectedTenantName={selectedTenant?.tenantName}
            setSelectedCategory={setSelectedCategory}
            os={osValue}
            fetchCategories={fetchCategories}
            resourceType={selectedResourceType.name}
            allSelectedResources={allSelectedResources}
          />
        </Box>
      </Box>

      <OperationSystemTab
        osValue={osValue}
        setOsValue={setOsValue}
        windowsContent={
          <Stack direction={"row"} spacing={3} sx={{ marginTop: "20px" }}>
            <ResouceTypeTable
              selectedResource={selectedResourceType}
              setSelectedResource={setSelectedResourceType}
            />
            <Box sx={{ marginTop: 13 }}>
              <CategoriesTable {...categoriesTableProps} os={"WINDOWS"} />
            </Box>
            <Box>
              <RadioFilter
                handleFilterResourceValue={handleFilterResourceValue}
                filterResourceValue={filterResourceValue}
              />
              <ResourcesTable {...resourceTableProps} os={"WINDOWS"} />
            </Box>
          </Stack>
        }
        linuxContent={
          <Stack direction={"row"} spacing={3} sx={{ marginTop: "20px" }}>
            <ResouceTypeTable
              selectedResource={selectedResourceType}
              setSelectedResource={setSelectedResourceType}
            />
            <Box sx={{ marginTop: 13 }}>
              <CategoriesTable {...categoriesTableProps} os={"LINUX"} />
            </Box>

            <Box>
              <RadioFilter
                handleFilterResourceValue={handleFilterResourceValue}
                filterResourceValue={filterResourceValue}
              />
              <ResourcesTable {...resourceTableProps} os={"LINUX"} />
            </Box>
          </Stack>
        }
      />
    </Box>
  );
};

export default ResourceCategories;
