import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Stack,
  Button,
  Box,
} from "@mui/material";

import { NGROK } from "../../../APIs";
import EditCategoryDialog from "./components/EditCategoryDialog";

const CategoriesTable = ({
  resourceType,
  selectedCategory,
  setSelectedCategory,
  selectedTenantName,
  allSelectedResources,
  os,
  fetchResources,
  fetchCategories,
  setAllSelectedResources,
  categoryList,
  setCategoryList,
}) => {
  const typeOfUpdatedResourceForApi =
    resourceType === "App"
      ? "apps"
      : resourceType === "Folder"
      ? "folders"
      : resourceType === "Group"
      ? "groups"
      : resourceType === "Publisher"
      ? "publishers"
      : "url-control";

  const deleteCategoryFromResource = async (categoryName, resourceId) => {
    try {
      await axios.delete(
        `${NGROK}/api/category/${categoryName}/${typeOfUpdatedResourceForApi}/${resourceId}`
      );
    } catch (error) {
      console.log(error, "eeror during deleting category");
    }
  };

  const deleteGlobalCategory = async (categoryName) => {
    try {
      await axios.delete(
        `${NGROK}/api/category/${categoryName}/${resourceType.toUpperCase()}/${os}`
      );
      setCategoryList((prev) => prev.filter((el) => el.name !== categoryName));

      const updatedResources = allSelectedResources?.map((resource) => {
        if (
          resource.categories.includes(categoryName.toUpperCase()) ||
          resource.categories.includes(categoryName)
        ) {
          deleteCategoryFromResource(categoryName.toUpperCase(), resource.id);

          const updatedCategories = resource.categories.filter(
            (category) =>
              category !== categoryName.toUpperCase() &&
              category !== categoryName
          );

          return { ...resource, categories: updatedCategories };
        }

        return resource;
      });
      setAllSelectedResources(updatedResources);
    } catch {
      console.log("error during updating categories");
    }
  };

  const toLowerCaseExceptFirst = (str) =>
    `${str[0]}${str.slice(1).toLowerCase()}`;

  useEffect(() => {
    fetchCategories(os);
  }, [resourceType, os]);

  console.log(categoryList, "categoryList");
  console.log(os, "os categories ");

  return (
    <TableContainer
      sx={{
        height: "fit-content",
        minWidth: "300px",
        maxWidth: "430px",
        border: 1,
        marginTop: 15
      }}
      component={Paper}
    >
      <Table
        sx={{
          borderCollapse: "collapse",
          borderStyle: "hidden",
          width: "100%",
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell className="userName">
              <Typography fontSize={17} fontWeight={600}>
                Categories
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categoryList?.length
            ? categoryList.map((category) => (
                <TableRow
                  className={
                    category.name === selectedCategory?.name
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  key={category.name}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="userRowName userName"
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="userRowName userName"
                        fontSize={16}
                        fontWeight={500}
                      >
                        {toLowerCaseExceptFirst(category.name)}
                      </Typography>
                      <Stack
                        direction={"row"}
                        spacing={3}
                        sx={{ width: "150px" }}
                      >
                        <EditCategoryDialog
                          selectedCategory={selectedCategory}
                          setAllSelectedResources={setAllSelectedResources}
                          selectedTenantName={selectedTenantName}
                          fetchCategories={fetchCategories}
                          fetchResources={fetchResources}
                          os={os}
                          resourceType={resourceType}
                          setCategoryList={setCategoryList}
                          category={category}
                          allSelectedResources={allSelectedResources}
                        />
                        <Button
                          onClick={(e) => {
                            deleteGlobalCategory(category.name);
                            setSelectedCategory(null);
                            e.stopPropagation();
                          }}
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={
                            category.name === selectedCategory?.name
                              ? { color: "white" }
                              : {}
                          }
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTable;
