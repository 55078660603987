import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from "@mui/material";

const RadioFilter = ({
  handleFilterResourceValue,
  filterName,
  filterResourceValue,
}) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <FormControl sx={{ minWidth: "200px" }} >
      {/* <FormLabel id="demo-radio-buttons-group-label">{filterName}</FormLabel> */}
      <RadioGroup
        sx={{ display: "flex", flexDirection: "row" }}
        value={filterResourceValue}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="Show selected"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show selected"
        />
        <FormControlLabel
          value="Show unselected"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show unselected"
        />
        <FormControlLabel
          value="Show All"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show All"
        />
      </RadioGroup>
    </FormControl>
    </Box>
    
  );
};

export default RadioFilter;
