import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import RequestMessageDialog from "../components/RequestMessageDialog";
import useUserStore from "../../services/userStore";
import moment from "moment";

const ResourceRequestsUser = () => {
  const userNotifications = useUserStore((state) => state.userNotifications);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          minWidth: "800px",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Computer Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Category
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Request Message
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Privilege Level
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontSize: "16px" }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userNotifications.length
              ? userNotifications.map((notification) => (
                  <TableRow
                    key={notification.id}
                  >
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {moment(notification.createdAt).format(
                        "YYYY/MM/DD/HH:mm"
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.computerName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceType === "GROUP" &&
                      notification.resourceName === "Remote Users Desktop"
                        ? "SESSION"
                        : notification.resourceType}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <RequestMessageDialog
                        messageText={notification.requestMessage}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.selectedTime === 0 ? (
                        <Typography
                          fontWeight={500}
                          fontSize={16}
                          variant="body1"
                        >
                          PERMANENT
                        </Typography>
                      ) : (
                        <Stack spacing={2}>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            DYNAMIC
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            {notification.selectedTime} min
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }}>
                      <Button
                        variant="outlined"
                        color={
                          notification.requestStatus === "APPROVED"
                            ? "success"
                            : "error"
                        }
                      >
                        {notification.requestStatus === "DECLINE"
                          ? "DECLINED"
                          : notification.requestStatus}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceRequestsUser;
