import React, { useEffect, useState } from "react";

import axios from "axios";

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import ".//../pages.scss";
import Paper from "@mui/material/Paper";
import ApplicationsControl from "./ApplicationsControl";
import DataAccess from "./DataAccess";
import PrivilegeManagement from "./PrivilegeManagement";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { NGROK } from "../../../APIs";
import { useLocation } from "react-router-dom";
import URLcontrol from "./URLcontrol";

const EndpointsResources = () => {
  const location = useLocation();
  const activeDevice = location.state?.activeDevice;

  const [selectedResourceId, setSelectedResourcesId] = useState(myLocalStorage.getItem("selectedEndpointsResourceId") || 2);

  const [publishers, setPublishers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [folders, setFolders] = useState([]);
  const [applications, setApplications] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [userSearchValues, setUserSearchValues] = useState("");

  const userData = useUserStore((state) => state.user);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);
  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);
  const [showCount, setShowCount] = useState(50);

  const [groupsSearchValues, setGroupsSearchValues] = useState("");
  const [foldersSearchValues, setFoldersSearchValues] = useState("");

  const { latestTenant, latestComputerId, latestUserId, resourceId } =
    lastSession;

  const getUsers = () => {
    setUserSearchValues("");

    if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
      axios
        .get(`${NGROK}/api/${latestTenant}/computer-users/${latestComputerId}`)
        .then((res) => {
          if (res.data) {
            console.log(res.data, "respons TENANT ADMIN");
            setUsersList(res.data.content);
          }
        });
    } else if (userData.role === "TENANT_USER") {
      axios
        .get(
          `${NGROK}/api/${latestTenant}/computer-users/${latestComputerId}/users?email=${userData.email}`
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data, "respons TENANT USER");
            setUsersList(res.data);
          }
        });
    }
  };

  const getPublishers = async () => {
    if (selectedUser !== undefined) {
      setPublishers(undefined);

      const storageKey = `${latestTenant}/computers/${latestComputerId}/publishers-30min`;
      const storagePublishers = myLocalStorage.getItem(storageKey);

      if (storagePublishers && storagePublishers.length && isCachingEnabled) {
        setTimeout(() => {
          setPublishers(storagePublishers);
        }, 500);
      } else {
        const response = await axios.get(
          `${NGROK}/api/${latestTenant}/computers/${latestComputerId}/publishers`
        );
        setPublishers(response.data.content);

        myLocalStorage.setItem(storageKey, response.data, 30);
      }
    }
  };

  const getFolders = async () => {
    setFolders(undefined);
    setFoldersSearchValues("");

    if (selectedUser !== undefined) {
      const storageKey = `${latestTenant}/computers/${latestComputerId}/computer-users/${selectedUser.id}/folders-30min`;
      const storageFolders = myLocalStorage.getItem(storageKey);

      if (storageFolders && storageFolders.length && isCachingEnabled) {
        setTimeout(() => {
          setFolders(storageFolders);
        }, 500);
      } else {
        const response = await axios.get(
          `${NGROK}/api/${latestTenant}/computers/${latestComputerId}/computer-users/${selectedUser.id}/folders`
        );
        if (response.data) {
          setFolders(response.data.content);
          myLocalStorage.setItem(storageKey, response.data, 30);
        }
      }
    }
  };

  const getUserGroups = async () => {
    setGroups(undefined);
    setGroupsSearchValues("");

    if (selectedUser !== undefined) {
      const storageKey = `${latestTenant}/computer-user-groups/membership/${selectedUser.id}-30min`;
      const storageUserGroups = myLocalStorage.getItem(storageKey);

      if (storageUserGroups && storageUserGroups.length && isCachingEnabled) {
        setTimeout(() => {
          setGroups(storageUserGroups);
        }, 500);
      } else {
        axios
          .get(
            `${NGROK}/api/${latestTenant}/computer-user-groups/membership/${selectedUser?.id}`
          )
          .then((res) => {
            if (res.data) {
              setGroups(res.data.content);
              myLocalStorage.setItem(storageKey, res.data.content, 30);
            }
          });
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [selectedResourceId, userData, lastSession]);

  useEffect(() => {
    setShowCount(50);
    switch (selectedResourceId) {
      case 1:
        getPublishers();
        break;
      case 2:
        getFolders();
        break;
      case 3:
        getUserGroups();
        break;
      default:
        console.log("default");
    }
  }, [selectedResourceId, selectedUser]);

  useEffect(() => {
    if (usersList.length === 1) return setSelectedUser(usersList[0]);
    if (latestUserId && usersList.length) {
      const user = usersList.find((user) => user.id === latestUserId);

      if (user !== undefined) setSelectedUser(user);
      else setSelectedUser(usersList[0]);
    } else if (!latestUserId && usersList.length) setSelectedUser(usersList[0]);
  }, [usersList]);

  useEffect(() => {
    if (selectedUser) {
      setApplications([]);
    }
  }, [selectedUser]);

  useEffect(() => {
    myLocalStorage.setItem("selectedEndpointsResourceId", selectedResourceId);
  }, [selectedResourceId]);

  console.log(usersList, "usersList");
  console.log(activeDevice, "active device");
  console.log(selectedResourceId, "selectedResourceId");

  return (
    <Stack spacing={5} direction={"row"}>
      <Resources
        selectedResourceId={selectedResourceId}
        setSelectedResourcesId={setSelectedResourcesId}
        lastSession={lastSession}
        setLastSession={setLastSession}
      />
      <Stack direction={"row"} spacing={3}>
        {usersList && usersList.length && selectedUser ? (
          <GeneralTab
            activeDevice={activeDevice}
            users={usersList}
            selectedUserId={selectedUser.id}
            setSelectedUser={setSelectedUser}
            lastSession={lastSession}
            setLastSession={setLastSession}
            setUserSearchValues={setUserSearchValues}
            userSearchValues={userSearchValues}
          />
        ) : null}
        {selectedResourceId === 1 && selectedUser ? (
          <ApplicationsControl
            userData={userData}
            setApplications={setApplications}
            applications={applications}
            selectedUserId={selectedUser.id}
            publishers={publishers}
            selectedTenantName={latestTenant}
            setGroups={setGroups}
            showCount={showCount}
            setShowCount={setShowCount}
            selectedUser={selectedUser}
          />
        ) : null}
        {selectedResourceId === 2 && selectedUser ? (
          <DataAccess
            userData={userData}
            setFolders={setFolders}
            getFolders={getFolders}
            folders={folders}
            selectedTenantName={latestTenant}
            showCount={showCount}
            setShowCount={setShowCount}
            selectedUser={selectedUser}
            foldersSearchValues={foldersSearchValues}
            setFoldersSearchValues={setFoldersSearchValues}
          />
        ) : null}
        {selectedResourceId === 3 && selectedUser ? (
          <PrivilegeManagement
            userData={userData}
            groups={groups}
            setGroups={setGroups}
            getUserGroups={getUserGroups}
            selectedTenantName={latestTenant}
            selectedUser={selectedUser}
            showCount={showCount}
            setShowCount={setShowCount}
            setGroupsSearchValues={setGroupsSearchValues}
            groupsSearchValues={groupsSearchValues}
          />
        ) : null}
        {selectedResourceId === 4 && selectedUser ? (
          <URLcontrol
            userId={selectedUser.id}
            tenantName={latestTenant}
            computerId={latestComputerId}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  lastSession,
  setLastSession,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
    },
    {
      id: 2,
      title: "Data Access",
    },
    {
      id: 3,
      title: "Privilege Management",
    },
    {
      id: 4,
      title: "URL control",
    },
  ];

  return (
    <section>
      <TableContainer
        style={{ height: "fit-content", width: 180 }}
        component={Paper}
      >
        <Table sx={{ width: "100%" }} size="large">
          <TableHead>
            <TableRow>
              <TableCell className="userName">Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                  setLastSession({
                    ...lastSession,
                    resourceId: resource.id,
                  });
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  {resource.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

const GeneralTab = ({
  users,
  selectedUserId,
  setSelectedUser,
  setLastSession,
  lastSession,
  userSearchValues,
  setUserSearchValues,
}) => {
  const findUser = (e) => {
    setTimeout(() => {
      setUserSearchValues(e.target.value);
    }, 1500);
  };

  const filteredUsers = users.filter((user) => {
    if (userSearchValues === "") {
      return user;
    } else if (user.cn.toLowerCase().includes(userSearchValues.toLowerCase())) {
      return user;
    }
  });

  console.log(users, "users");
  return (
    <section>
      {users !== undefined && users.length ? (
        <TableContainer
          style={{ height: "fit-content", maxWidth: 400, minWidth: 200 }}
          component={Paper}
        >
          <Table sx={{ width: "100%" }} size="large">
            <TableHead>
              <TableRow>
                <TableCell className="userName">Users</TableCell>
              </TableRow>
              {users.length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find user ..."
                      id="searchForUser"
                      onChange={findUser}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow
                  className={
                    user.id === selectedUserId
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  key={user.cn}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="userRowName userName"
                    title={user.cn}
                    onClick={() => {
                      setSelectedUser(user);
                      setLastSession({
                        ...lastSession,
                        latestUserId: user.id,
                        latestUsername: user.cn,
                      });
                    }}
                  >
                    <Typography variant="h5" className="userRowName userName">
                      {user.cn}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : users == undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <p style={{ fontSize: "20px", fontWeight: "500" }}>
          This computer doesn't have any users.
        </p>
      )}
    </section>
  );
};

export default EndpointsResources;
