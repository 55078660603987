import React, { useState } from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useUserStore from "../../services/userStore";
import { postLastSession } from "../../services/Helpers";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const navigate = useNavigate();

  const lastSession = useUserStore((state) => state.lastSession);
  const doLogOutState = useUserStore((state) => state.logOut);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const navigateToAccount = () => {
    navigate("/account");
  };

  const handleSignOut = async () => {
    navigate("/");
    if (lastSession.latestTenant) {
      postLastSession({
        ...lastSession,
      })
        .then(() => doLogOutState())
        .catch((error) => {
          console.log(error, "error log out");
          doLogOutState();
        });
    } else doLogOutState();
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={navigateToAccount}>Account</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
