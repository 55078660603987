import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import "./pages.scss";

import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import "./pages.scss";
import { myLocalStorage } from "../../components/StorageHelper";
import useUserStore from "../../services/userStore";
import StyledTooltip from "../components/StyledTooltip";
import RadioAuthFilter from "../components/RadioAuthFilter";

const ProfileApplicationsControl = ({
  publishers,
  selectedProfile,
  setSelectedPublisher,
  selectedPublisher,
  applications,
  setApplications,
  userData,
  showCount,
  setShowCount,
}) => {
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);

  const [hideEmptyPublishers, setHideEmptyPublishers] = useState(true);
  const [selectedPublisherName, setSelectedPublisherName] = useState("");
  const [filterAppsValue, setFilterAppsValue] = useState("Show All");
  const [appsSearchValues, setAppsSearchValues] = useState("");
  const [publisherSearchValues, setPublisherSearchValues] = useState("");
  const [unsignedAppsPublisher, setUnsignedAppsPublisher] = useState(null);
  const [allAppsPublisher, setAllAppsPublisher] = useState({
    id: "allAppsPublisher",
    frinedlyName: "allAppsPublisher",
  });

  const filterPublishers = (publishers) => {
    if (publishers?.length) {
      const PublishersWithoutDuplicates = publishers
        .map((publisher) => ({ ...publisher, ids: [publisher.id] }))
        .reduce((acc, current) => {
          const x = acc.find(
            (publisher) => publisher.friendlyName === current.friendlyName
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            x.ids.push(current.id);
            return acc;
          }
        }, []);
      return hideEmptyPublishers
        ? PublishersWithoutDuplicates.filter((publisher) =>
            publisher.containsApps ? publisher : null
          )
        : PublishersWithoutDuplicates;
    }
  };

  const getApplications = async (publisherIds) => {
    setApplications(undefined);
    setAppsSearchValues("");

    const storageKey = `${selectedProfile.os}/profiles/${selectedProfile.id}apps-30min`;
    const storageApps = myLocalStorage.getItem(storageKey);

    if (storageApps && storageApps.length && isCachingEnabled) {
      setTimeout(() => {
        setApplications(storageApps);
      }, 500);
    } else {
      const appArr = [];

      const getUrl = (publisherId) =>
        `${NGROK}/api/${selectedProfile.os}/profiles/${selectedProfile.id}/publishers/${publisherId}/apps`;

      const fetchData = async (url) => {
        const result = await axios.get(url);
        if (result.data) {
          appArr.push(result.data.content);
        }
      };

      if (selectedPublisher.friendlyName !== "Unsigned Apps") {
        await Promise.all(
          publisherIds.map((publisherId) => fetchData(getUrl(publisherId)))
        );
      } else {
        await fetchData(getUrl(selectedPublisher.id));
      }
      const appsSortedById = appArr.flat().sort((a, b) => a.appId - b.appId);
      setApplications(appsSortedById);

      if (appArr.length) {
        myLocalStorage.setItem(storageKey, appArr.flat(), 30);
      }
    }
  };

  const changeApplicationStatus = async (application, groupStatus) => {
    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
        ? "BLOCKED"
        : "DYNAMIC";
    setApplications(
      applications.map((el) =>
        el.appId === application.appId
          ? { ...application, profileResource }
          : el
      )
    );
    try {
      await axios.put(`${NGROK}/api/profiles/apps/update`, {
        appId: application.appId,
        email: userData.email,
        fingerprintSha256: application.fingerprintSha256,
        groupStatus,
        profileId: application.profileId,
      });
    } catch (error) {
      setApplications(
        applications.map((el) =>
          el.appId === application.appId ? application : el
        )
      );
    }
  };

  const findApplication = (e) => {
    console.log(e.target.value, "search value");
    setAppsSearchValues(e.target.value);
  };

  const findPublisher = (e) => {
    setTimeout(() => {
      setPublisherSearchValues(e.target.value);
    }, 1500);
  };

  const filteredApplications =
    applications?.length &&
    applications
      .filter((application) => {
        if (appsSearchValues === "") {
          return application;
        } else if (
          application.appName
            .toLowerCase()
            .includes(appsSearchValues.toLowerCase())
        ) {
          return application;
        }
      })
      .filter((application) => {
        if (filterAppsValue === "Show All") return application;

        if (filterAppsValue === "Show Yes only")
          return application.profileResource === "ALLOWED";
        else if (filterAppsValue === "Show No only")
          return application.profileResource === "BLOCKED";
        else if (filterAppsValue === "Show Dynamic only")
          return application.profileResource === "DYNAMIC";
      });

  const filteredPublishers =
    filterPublishers(publishers)?.length &&
    filterPublishers(publishers).filter((publisher) => {
      if (publisherSearchValues === "") {
        return publisher.friendlyName !== "Unsigned Apps";
      } else if (
        publisher.friendlyName
          .toLowerCase()
          .includes(publisherSearchValues.toLowerCase())
      ) {
        return publisher.friendlyName !== "Unsigned Apps";
      }
    });

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const getAllApplications = async () => {
    const response = await axios.get(
      `${NGROK}/api/profiles/get-all-apps?profileId=${selectedProfile.id}`
    );

    setApplications(response.data);
  };

  const getInheritedAppsStatusValue = (app) => {
    if (app.profileResource === "INHERITED") {
      return `(${app.trustLevel})`;
    }
  };

  useEffect(() => {
    if (publishers?.length) {
      const unsignedAppsPublisher = publishers?.find(
        (el) => el.friendlyName === "Unsigned Apps"
      );
      setUnsignedAppsPublisher(unsignedAppsPublisher);

      const publisher = filterPublishers(publishers).find(
        (publisher) => publisher.id === selectedPublisher?.id
      );
      if (publisher) setSelectedPublisher(publisher);
    }
  }, [publishers]);

  useEffect(() => {
    if (
      selectedPublisher !== undefined &&
      selectedPublisher?.id !== "allAppsPublisher"
    ) {
      getApplications(selectedPublisher.ids);
      setShowCount(50);
    }
  }, [selectedPublisher]);

  return (
    <section className=" applicationTab">
      <TableContainer
        style={{ height: "fit-content", minWidth: "300px" }}
        component={Paper}
      >
        {filteredPublishers?.length ? (
          <Table sx={{ height: "fit-content", maxWidth: "100%" }} size="large">
            <TableHead>
              <TableRow>
                <TableCell className="userName">
                  Publisher and App groups
                </TableCell>
              </TableRow>
              {filterPublishers(publishers)?.length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find publisher ..."
                      id="searchForPublisher"
                      onChange={findPublisher}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={hideEmptyPublishers}
                  onChange={(e) => setHideEmptyPublishers(e.target.checked)}
                />
                <Typography fontSize={19}>Hide empty publishers</Typography>
              </TableRow>
              <TableRow
                className={
                  allAppsPublisher.id === selectedPublisher?.id
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => {
                  setSelectedPublisher(allAppsPublisher);
                  getAllApplications();
                }}
              >
                <TableCell
                  scope="row"
                  className="userName"
                  component="td"
                  sx={{ marginBottom: "30px" }}
                >
                  All Apps
                </TableCell>
              </TableRow>
              {unsignedAppsPublisher ? (
                <TableRow
                  className={
                    unsignedAppsPublisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    setSelectedPublisher(unsignedAppsPublisher);
                  }}
                >
                  <TableCell
                    scope="row"
                    className="userName"
                    component="td"
                    sx={{ marginBottom: "30px" }}
                  >
                    Unsigned Apps
                  </TableCell>
                </TableRow>
              ) : null}
              <Divider
                sx={{
                  margin: "10px auto",
                  width: "150px",
                }}
              />
              {filteredPublishers.map((publisher) => (
                <TableRow
                  key={publisher.id}
                  className={
                    publisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    setSelectedPublisher(publisher);
                    setSelectedPublisherName(publisher.friendlyName);
                  }}
                >
                  <StyledTooltip
                    arrow
                    title={
                      <>
                        <Typography color="inherit">
                          {`Subject: ${publisher.subject}`}
                        </Typography>
                        <br />
                        <Typography color="inherit">
                          {`Thumbprint: ${publisher.thumbprint}`}
                        </Typography>
                      </>
                    }
                    placement="left"
                  >
                    <TableCell
                      scope="row"
                      className="groupRowName userName"
                      component="td"
                    >
                      <>{publisher.friendlyName}</>
                    </TableCell>
                  </StyledTooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : publishers === undefined ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              There are no any publishers.
            </p>
          </Box>
        )}
      </TableContainer>
      {applications !== undefined && applications.length ? (
        <TableContainer component={Paper}>
          <Stack direction={"column"} spacing={2} sx={{ padding: "15px" }}>
            <RadioAuthFilter
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
              filterResourceValue={filterAppsValue}
            />
            {applications?.length > 10 ? (
              <TextField
                fullWidth
                sx={{ minWidth: "200px" }}
                label="Search the Applications..."
                id="searchForApplication"
                onChange={findApplication}
              />
            ) : null}
          </Stack>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Application</TableCell>
                <TableCell className="userName">Trust level</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredApplications?.map((application) => (
                <TableRow className="groupRow" key={application.id}>
                  <StyledTooltip
                    arrow
                    title={
                      <>
                        <Typography color="inherit">
                          {`Path: ${application.appPath}`}
                        </Typography>
                        <br />
                        <Typography color="inherit">
                          {`Size: ${application.size}`}
                        </Typography>
                        <br />
                        <Typography color="inherit">
                          {`Fingerprint: ${application.fingerprintSha256}`}
                        </Typography>
                      </>
                    }
                    placement="left"
                  >
                    <TableCell className="userName">
                      {application.appName}
                    </TableCell>
                  </StyledTooltip>
                  <TableCell>
                    <Select
                      sx={{ minWidth: "100px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        application.profileResource === "ALLOWED"
                          ? "ENABLED"
                          : application.profileResource === "BLOCKED"
                          ? "DISABLED"
                          : application.profileResource === "DYNAMIC"
                          ? "DYNAMIC"
                          : null
                      }
                      size="small"
                      onChange={(event) =>
                        changeApplicationStatus(application, event.target.value)
                      }
                    >
                      <MenuItem value={"ENABLED"}>YES</MenuItem>
                      <MenuItem value={"DISABLED"}>NO</MenuItem>
                      <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                      <MenuItem value={"INHERITED"}>INHERITED {getInheritedAppsStatusValue(application)}</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              {applications?.length > showCount ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : applications == undefined && selectedPublisher?.id ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            Publisher doesn't have any applications.
          </p>
        </Box>
      )}
    </section>
  );
};

export default ProfileApplicationsControl;
