import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { NGROK } from "../../../../APIs";
import useAxios from "../../../../hooks/useAxios";
import { generalProfileOrder } from "../../../../services/Helpers";

const ProfilesList = ({
  categoryProfiles,
  selectedTenantName,
  setProfilesWithTrustLevels,
  profilesWithTrustLevels,
  os,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [profilesList, setProfilesList] = useState([]);

  const { data: allProfiles } = useAxios(
    `${NGROK}/api/profiles/inherited?tenantName=${selectedTenantName}`
  );

  const handleProfilesTrustLevel = (profile, value) => {
    if (isChecked) {
      setProfilesWithTrustLevels((prevProfiles) =>
        prevProfiles.map((prevProfile) => ({
          ...prevProfile,
          trustLevel: value,
        }))
      );
    } else
      setProfilesWithTrustLevels((prevProfiles) =>
        prevProfiles.map((prevProfile) =>
          prevProfile.name === profile.name
            ? { ...prevProfile, trustLevel: value }
            : prevProfile
        )
      );
  };

  const handleCheckbox = (value) => {
    setIsChecked(!isChecked);
    if (!value) {
      return;
    } else {
      setProfilesWithTrustLevels((prevProfiles) =>
        prevProfiles.map((prevProfile) => ({
          ...prevProfile,
          trustLevel: prevProfiles[0].trustLevel,
        }))
      );
    }
  };

  useEffect(() => {
    const profilesFilteredByOs = allProfiles?.filter(
      (profile) => profile.os.toUpperCase() === os.toUpperCase()
    );

    const profilesSorted = profilesFilteredByOs?.toSorted((a, b) => {
      const indexA = generalProfileOrder.findIndex((word) =>
        a.name.includes(word)
      );
      const indexB = generalProfileOrder.findIndex((word) =>
        b.name.includes(word)
      );

      return indexA - indexB;
    });

    if (profilesSorted) setProfilesList(profilesSorted);
    else setProfilesList([]);
  }, [allProfiles, os]);

  useEffect(() => {
    if (profilesList.length && !profilesWithTrustLevels.length) {
      console.log("here is enabled");
      const newProfilesWithTrustLevel = profilesList.map((profile) => ({
        ...profile,
        trustLevel: "ENABLED",
      }));
      setProfilesWithTrustLevels(newProfilesWithTrustLevel);
    }
  }, [profilesList]);

  useEffect(() => {
    if (categoryProfiles) setProfilesWithTrustLevels(categoryProfiles);
    return () => setProfilesWithTrustLevels([]);
  }, []);

  console.log(selectedTenantName, "selectedTenantName");
  console.log(profilesWithTrustLevels, "profileTrustLevels");
  console.log(profilesList, "profilesList");

  return (
    <>
      {profilesWithTrustLevels.length ? (
        <Box width={"100%"} component={"fieldset"} borderRadius={1}>
          <legend>Select trust level for each profile</legend>

          <FormControlLabel
            control={
              <Checkbox
                value={isChecked}
                onClick={(e) => {
                  handleCheckbox(e.target.value);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Set same trust level for all profiles"
          />

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={528}
            sx={{ overflowX: "auto", overflowY: "hidden" }}
          >
            {profilesWithTrustLevels.length
              ? profilesWithTrustLevels.map((profile, index) => (
                  <Box
                    key={profile.name}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={"80px"}
                  >
                    <Typography variant={"body1"}>{profile.name}</Typography>
                    <FormControl sx={{ width: 110 }}>
                      <InputLabel htmlFor="demo-simple-select">
                        Trust level
                      </InputLabel>
                      <Select
                        name={profile.name}
                        disabled={
                          isChecked &&
                          profilesWithTrustLevels[0].name !== profile.name
                        }
                        value={profile.trustLevel}
                        onChange={(e) =>
                          handleProfilesTrustLevel(profile, e.target.value)
                        }
                        label="Trust level"
                        id="demo-simple-select"
                        sx={{ height: 45 }}
                      >
                        <MenuItem value={"ENABLED"}>Yes</MenuItem>
                        <MenuItem value={"DISABLED"}>No</MenuItem>
                        <MenuItem value={"DYNAMIC"}>Dynamic</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ))
              : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ProfilesList;
