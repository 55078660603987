import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";

const TenantSelection = ({
  setSelectedTenant,
  selectedTenant,
}) => {
  const userEmail = useUserStore((state) => state.user.email);
  const [tenantsList, setTenantsList] = useState([]);

  const fetchTenants = async () => {
    const response = await axios.get(
      `${NGROK}/api/get-all-domains?email=${userEmail}`
    );
    return response.data;
  };

  useEffect(() => {
    const getTenants = async () => {
      const tenants = await fetchTenants();
      setTenantsList(tenants);
    };
    getTenants();
  }, [userEmail]);

  useEffect(() => {
    if (tenantsList.length) setSelectedTenant(tenantsList[0]);
  }, [tenantsList]);


  return (
    <>
      {tenantsList.length ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={
            selectedTenant
              ? selectedTenant.tenantName
              : tenantsList[0].tenantName
          }
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{
            width: 300,
            marginTop: "15px",
            backgroundColor: "white",
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedTenant(null);
            }
            const tenant = tenantsList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setSelectedTenant(tenant);
            }
          }}
        />
      ) : null}
    </>
  );
};

export default TenantSelection;
