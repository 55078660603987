import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Divider as MuiDivider,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red, yellow } from "@mui/material/colors";

import BarChart from "./BarChart";
import LineChart from "./LineChart";
import Stats from "./Stats";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";

const Divider = styled(MuiDivider)(spacing);

function Default() {
  const userData = useUserStore((state) => state.user);
  const [identitiesCount, setIdentitiesCount] = useState(0);
  const [identitiesCountAtMonthStart, setIdentitiesCountAtMonthStart] =
    useState(0);
  const [resourcesCount, setResourcesCount] = useState(0);
  const [resourcesCountAtMonthStart, setResourcesCountAtMonthStart] =
    useState(0);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [suspiciousAccountsCount, setSuspiciousAccountsCount] = useState(0);
  const [
    suspiciousAccountsCountAtMonthStart,
    setSuspiciousAccountsCountAtMonthStart,
  ] = useState(0);
  const [identitiesPercentegDifferences, setIdentitiesPercentegDifferences] =
    useState(0);
  const [resourcesPercentegDifferences, setResourcesPercentegDifferences] =
    useState(0);
  const [
    activeSessionsPercentegDifferences,
    setActiveSessionsPercentegDifferences,
  ] = useState(0);
  const [
    suspiciousAccountsPercentegDifferences,
    setSuspiciousAccountsPercentegDifferences,
  ] = useState(0);
  const [tenantList, setTenantList] = useState([]);
  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);

  const { latestTenant } = lastSession;

  const calculatePercentageDifference = (currentCount, atMonthStartCount) => {
    const smallerNumber = Math.min(currentCount, atMonthStartCount);
    const biggerNumber = Math.max(currentCount, atMonthStartCount);

    if (currentCount === 0 && atMonthStartCount !== 0) return "-100";
    else if (currentCount !== 0 && atMonthStartCount === 0) return "100";
    else if (currentCount === 0 && atMonthStartCount === 0) return "0.00";
    else {
      const differenceInPercentage =
        ((biggerNumber - smallerNumber) / smallerNumber) * 100;

      console.log(differenceInPercentage, "differenceInPercentage");
      console.log(currentCount, "currentCount");
      console.log(atMonthStartCount, "atMonthStartCount");
      return currentCount > atMonthStartCount ||
        currentCount === atMonthStartCount
        ? `${differenceInPercentage.toFixed(2)}`
        : `-${differenceInPercentage.toFixed(2)}`;
    }
  };

  const fetchStatisticsData = async (endpoint, setter) => {
    const response = await axios.get(
      `${NGROK}/api/statistics${endpoint}?tenantName=${latestTenant}`
    );

    setter(response.data);
  };

  useEffect(() => {
    if (tenantList?.length === 1)
      setLastSession({
        ...lastSession,
        latestTenant: tenantList[0].tenantName,
      });
  }, [tenantList]);

  useEffect(() => {
    const storageKey = `get-all-domains?email=${userData.email}-30min`;

    const storageDomains = myLocalStorage.getItem(storageKey);

    if (storageDomains && storageDomains.length) {
      setTenantList([]);
      setTimeout(() => {
        setTenantList(storageDomains);
      }, 500);
    } else {
      const fetchTenants = async () => {
        const response = await axios.get(
          `${NGROK}/api/get-all-domains?email=${userData.email}`
        );
        setTenantList(response.data);
        myLocalStorage.setItem(storageKey, response.data, 30);
      };
      fetchTenants();
    }
  }, [userData]);

  const fetchIdentetiesCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${latestTenant}/computer-users/count`
      );
      setIdentitiesCount(response.data);
    } catch (error) {
      console.log(error, "error fetchIdentetiesCount");
    }
  }, [latestTenant]);

  const fetchResourcesCount = useCallback(async () => {
    try {
      const response = await axios.all([
        axios.get(`${NGROK}/api/${latestTenant}/groups/count`),
        axios.get(`${NGROK}/api/${latestTenant}/apps/count`),
        axios.get(`${NGROK}/api/${latestTenant}/folders/count`),
      ]);
      const result = response.reduce((acc, current) => {
        return acc + current.data;
      }, 0);
      setResourcesCount(result);
    } catch (error) {
      console.log(error, "error fetchResourcesCount");
    }
  }, [latestTenant]);

  const fetchSessionsCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/sessions/${latestTenant}/count`
      );
      setSessionsCount(response.data);
    } catch (error) {
      console.log(error, "error fetchSessionsCount");
    }
  }, [latestTenant]);

  const fetchSuspiciousAccountsCount = useCallback(() => {
    let suspiciousAccounts;
    try {
      if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${latestTenant}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      } else if (userData.role === "TENANT_USER") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${latestTenant}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      }
      if (suspiciousAccounts === undefined) suspiciousAccounts = [];
      setSuspiciousAccountsCount(suspiciousAccounts.length);
    } catch (error) {
      console.log(error, "error setSuspiciousAccountsCount");
    }
  }, [latestTenant]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      identitiesCount,
      identitiesCountAtMonthStart
    );
    setIdentitiesPercentegDifferences(percentegDifferences);
  }, [identitiesCount, identitiesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      resourcesCount,
      resourcesCountAtMonthStart
    );
    setResourcesPercentegDifferences(percentegDifferences);
  }, [resourcesCount, resourcesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      suspiciousAccountsCount,
      suspiciousAccountsCountAtMonthStart
    );
    setSuspiciousAccountsPercentegDifferences(percentegDifferences);
  }, [suspiciousAccountsCount, suspiciousAccountsCountAtMonthStart]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchIdentetiesCount();
      fetchResourcesCount();
      fetchSessionsCount();
      fetchSuspiciousAccountsCount();
    }, 5000);
    return () => clearInterval(interval);
  }, [
    fetchIdentetiesCount,
    fetchResourcesCount,
    fetchSessionsCount,
    fetchSuspiciousAccountsCount,
  ]);

  useEffect(() => {
    fetchIdentetiesCount();
    fetchResourcesCount();
    fetchSessionsCount();
    fetchSuspiciousAccountsCount();
    fetchStatisticsData(
      "/computers-users/count",
      setIdentitiesCountAtMonthStart
    );
    fetchStatisticsData("/resources/count", setResourcesCountAtMonthStart);
    fetchStatisticsData(
      "/computers-users/suspicious/count",
      setSuspiciousAccountsCountAtMonthStart
    );
  }, [latestTenant]);

  console.log(resourcesCount, "resourcesCount");
  console.log(resourcesCountAtMonthStart, "resourcesCountAtMonthStart");
  return (
    <React.Fragment>
      {tenantList?.length > 1 ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          defaultValue={
            lastSession?.latestTenant ? latestTenant : tenantList[0].tenantName
          }
          options={tenantList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setIdentitiesCount(0);
              setResourcesCount(0);
              setSessionsCount(0);
              setSuspiciousAccountsCount(0);
            }
            const tenant = tenantList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setLastSession({
                ...lastSession,
                latestTenant: tenant.tenantName,
              });
            }
          }}
        />
      ) : null}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Identities"
            amount={
              identitiesCount !== undefined ? (
                identitiesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${identitiesPercentegDifferences}%`}
            percentagecolor={
              identitiesPercentegDifferences > 0
                ? green[500]
                : identitiesPercentegDifferences == 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Resources"
            amount={
              resourcesCount !== undefined ? (
                resourcesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${resourcesPercentegDifferences}%`}
            percentagecolor={
              resourcesPercentegDifferences > 0
                ? green[500]
                : resourcesPercentegDifferences == 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Active Sessions"
            amount={
              sessionsCount !== undefined ? sessionsCount : <CircularProgress />
            }
            chip="Today"
            percentagetext={`${activeSessionsPercentegDifferences}%`}
            percentagecolor={
              activeSessionsPercentegDifferences > 0
                ? green[500]
                : activeSessionsPercentegDifferences == 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Suspicious accounts"
            amount={
              suspiciousAccountsCount !== undefined ? (
                suspiciousAccountsCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${suspiciousAccountsPercentegDifferences}%`}
            percentagecolor={
              suspiciousAccountsPercentegDifferences > 0
                ? green[500]
                : suspiciousAccountsPercentegDifferences == 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart selectedTenantName={ latestTenant} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid> */}
      </Grid>
      {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default Default;
