import React, {useState} from "react";

import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";

const OperationSystemTab = ({ windowsContent, linuxContent, osValue, setOsValue }) => {

  const handleChange = (event, newValue) => {
    setOsValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={osValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} display={"flex"} justifyContent={"center"}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="WINDOWS" value={"WINDOWS"} />
            <Tab label="LINUX" value={"LINUX"} />
            {/* <Tab label="Item Three" value="3" /> */}
          </TabList>
        </Box>
        <CustomTabPanel value={osValue} index={"WINDOWS"}>{windowsContent}</CustomTabPanel>
        <CustomTabPanel value={osValue} index={"LINUX"}>{linuxContent}</CustomTabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  console.log(index, "index tab")
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default OperationSystemTab;
