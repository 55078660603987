import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { resourceTypeListForCategoriesPage as resourceTypeList } from "../../../services/Helpers";

const ResouceTypeTable = ({ selectedResource, setSelectedResource }) => {
  console.log(resourceTypeList, "resourceTypeList");
  console.log(selectedResource, "selectedResource local");

  return (
    <>
      <TableContainer
        sx={{ height: "fit-content", maxWidth: "200px", border: 1, marginTop: 15 }}
        component={Paper}
      >
        <Table sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            width: "100%",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}>
          <TableHead>
            <TableRow>
              <TableCell className="userName">
                <Typography fontSize={17} fontWeight={600}>
                  Resource Type
                </Typography>{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceTypeList.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResource.id
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="userRowName userName"
                  title={resource.name}
                  onClick={() => setSelectedResource(resource)}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    className="userRowName userName"
                  >
                    {resource.name}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResouceTypeTable;
