import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { NGROK } from "../../../../APIs";
import {
  sortOrderLinuxProfiles,
  sortOrderWindowsProfiles,
} from "../../../../services/Helpers";

const ProfileSelection = ({
  setSelectedProfile,
  selectedTenant,
  os,
  selectedProfile,
}) => {
  const [profileList, setProfileList] = useState([]);

  const fetchTenantProfiles = async (tenantName) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/inherited?tenantName=${tenantName}`
      );

      const linuxProfiles = response.data
        .filter((profile) => profile.os === "linux")
        .sort((a, b) => {
          const aIndex = sortOrderLinuxProfiles.indexOf(a.name);
          const bIndex = sortOrderLinuxProfiles.indexOf(b.name);
          return aIndex - bIndex;
        });
      const windowsProfiles = response.data
        .filter((profile) => profile.os === "windows")
        .sort((a, b) => {
          const aIndex = sortOrderWindowsProfiles.indexOf(a.name);
          const bIndex = sortOrderWindowsProfiles.indexOf(b.name);
          return aIndex - bIndex;
        });

      if (os === "Windows") setProfileList(windowsProfiles);
      else if (os === "Linux") setProfileList(linuxProfiles);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (selectedTenant) fetchTenantProfiles(selectedTenant.tenantName);
  }, [selectedTenant]);

  useEffect(() => {
    if (profileList.length) setSelectedProfile(profileList[0]);
  }, [profileList]);

  console.log(selectedTenant, "profile component");
  return (
    <>
      {profileList.length && selectedProfile ? (
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedProfile.name}
        >
          {profileList?.map((profile) => (
            <FormControlLabel
              key={profile.name}
              value={profile.name}
              control={<Radio />}
              label={profile.name}
              onChange={() => setSelectedProfile(profile)}
            />
          ))}
        </RadioGroup>
      ) : null}
    </>
  );
};

export default ProfileSelection;
