import React from "react";
import styled from "styled-components/macro";

import LogoutTimer from "./LogoutTimer";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  text-align: center;
  font-size: 16px;
  color: #ffffff;
`;

const CounterBlock = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 18px;
`;

const LogoutContainer = () => {
  return (
    <Container>
      <TopText>Time to sign out:</TopText>
      <CounterBlock>
        <LogoutTimer />
      </CounterBlock>
    </Container>
  );
};

export default LogoutContainer;
