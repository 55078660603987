import React, { useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";


import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";


import ".//../pages.scss";
import { Table } from "@mui/material";
import { useState } from "react";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import useUserStore from "../../../services/userStore";
import CreateUrlDialog from "./components/CreateUrlDialog";
import UrlControlRow from "./components/UrlControlRow";

const URLcontrol = ({ tenantName, userId, computerId }) => {
  const resourcesDefaultTime = useUserStore(
    (state) => state.resourcesDefaultTime
  );

  const [filterResourceValue, setFilterResourceValue] = useState("Show All");
  const [searchValue, setSearchValue] = useState("");
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [urlControlList, setUrlControlList] = useState([]);

  const fetchUrlControlData = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${tenantName}/computers/${computerId}/computer-users/${userId}/url-control`
      );
      const sortedUrls = response.data.content?.sort(
        (a, b) => a.urlId - b.urlId
      );
      console.log(sortedUrls, "sortedUrls");
      setUrlControlList(sortedUrls);
    } catch (error) {
      console.error(error, "error during fetching urls");
    }
  };
  const { vertical, horizontal, open } = snackbarSettings;

  const urlControls = urlControlList?.length
    ? urlControlList
        .filter((resource) => {
          if (searchValue === "") {
            return resource;
          } else if (
            resource.name.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return resource;
          }
        })
        .filter((resource) => {
          if (filterResourceValue === "Show All") return resource;
          else if (filterResourceValue === "Show Yes only")
            return resource.groupStatus === "ENABLED";
          else if (filterResourceValue === "Show No only")
            return resource.groupStatus === "DISABLED";
          else if (filterResourceValue === "Show Dynamic only")
            return resource.groupStatus === "DYNAMIC";
        })
    : [];

  const findResource = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
    }, 1500);
  };

  const handleFilterResourceValue = (e) => {
    setFilterResourceValue(e.target.value);
  };

  useEffect(() => {
    fetchUrlControlData();
  }, [userId]);

  console.log(urlControlList, "urlControlList");
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      <TableContainer component={Paper}  sx={{ height: "fit-content" }}>
        <Box display={"flex"} justifyContent={"space-between"} p={5}>
          <RadioAuthFilter
            filterResourceValue={filterResourceValue}
            filterName={"Filter by privilege"}
            handleFilterResourceValue={handleFilterResourceValue}
          />
          <CreateUrlDialog
            fetchUrlControlData={fetchUrlControlData}
            tenantName={tenantName}
            computerId={computerId}
          />
          {/* {urlControlList?.length > 10 ? (
            <TextField
              fullWidth
              sx={{ minWidth: "200px" }}
              label="Search the Groups..."
              id="searchForGroup"
              onChange={findResource}
            />
            
          ) : null} */}
        </Box>
        <Table
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            width: "100%",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell className="userName">
                <Typography fontSize={"inherit"} fontWeight={"inherit"}>
                  URL control
                </Typography>
              </TableCell>
              <TableCell className="userName" align="center">
                Trust level
              </TableCell>
              <TableCell className="userName" align="center">
                Management
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {urlControls?.map((urlControl) => (
              <React.Fragment key={urlControl.name}>
                <UrlControlRow
                  fetchUrlControlData={fetchUrlControlData}
                  urlControl={urlControl}
                  tenantName={tenantName}
                  userId={userId}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default URLcontrol;
