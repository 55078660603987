import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import moment from "moment";
import axios from "axios";
import RequestMessageDialog from "../components/RequestMessageDialog";
import useUserStore from "../../services/userStore";
import { NGROK } from "../../APIs";

const ResourceRequests = () => {
  const userData = useUserStore((state) => state.user);
  const adminNotifications = useUserStore((state) => state.adminNotifications);

  const approveRequest = async (id, status) => {
    await axios.put(`${NGROK}/api/access/update`, {
      accessId: id,
      requestStatus: status,
    });
  };

  const updateGroupStatus = async (notification) => {
    const groupStatus = notification.selectedTime ? "DYNAMIC" : "ENABLED";

    if (notification.resourceType === "GROUP") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user-groups/membership`,
        {
          userId: notification.userId,
          groupId: notification.resourceId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
        }
      );
    } else if (notification.resourceType === "FOLDER") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user/folders/timer`,
        {
          userId: notification.userId,
          folderId: notification.resourceId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
        }
      );
    } else if (notification.resourceType === "APP") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user/publishers/apps/timer`,
        {
          userId: notification.userId,
          appId: notification.resourceId,
          publisherId: notification.publisherId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
        }
      );
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          minWidth: "800px",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Requested By
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Computer Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Category
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Justification
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Privilege Level
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                sx={{ color: "white", fontSize: "16px" }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminNotifications.length
              ? adminNotifications.map((notification) => (
                  <TableRow
                    key={notification.id}
                    // className={
                    //   session.sessionName === activeSession.sessionName
                    //     ? "tenantRow activeTenantRow"
                    //     : "tenantRow"
                    // }
                  >
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Typography fontSize={14} variant="subtitle2">
                        {moment(notification.createdAt).format(
                          "YYYY/MM/DD/HH:mm"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Typography fontSize={14} variant="subtitle2">
                        {notification.email}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.computerName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceType === "GROUP" &&
                      notification.resourceName === "Remote Users Desktop"
                        ? "SESSION"
                        : notification.resourceType}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }}>
                      <RequestMessageDialog
                        messageText={notification.requestMessage}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.privilegeLevel !== "DYNAMIC" ? (
                        <Typography
                          fontWeight={500}
                          fontSize={16}
                          variant="body1"
                        >
                          PERMANENT
                        </Typography>
                      ) : (
                        <Stack spacing={2}>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            DYNAMIC
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            {notification.selectedTime} min
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                    {notification.requestStatus === "PENDING" ? (
                      <>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          <Button
                            onClick={() => {
                              approveRequest(notification.id, "APPROVED");
                              updateGroupStatus(notification);
                            }}
                            variant="outlined"
                          >
                            Approve
                          </Button>
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          <Button
                            onClick={() => {
                              approveRequest(notification.id, "DECLINE");
                            }}
                            color="error"
                            variant="outlined"
                          >
                            Decline
                          </Button>
                        </TableCell>
                      </>
                    ) : notification.requestStatus === "APPROVED" ? (
                      <TableCell
                        colSpan={2}
                        sx={{ fontSize: "16px" }}
                        align="center"
                      >
                        <Button color="success" variant="outlined">
                          APPROVED
                        </Button>
                      </TableCell>
                    ) : notification.requestStatus === "DECLINE" ? (
                      <TableCell
                        colSpan={2}
                        sx={{ fontSize: "16px" }}
                        align="center"
                      >
                        <Button color="error" variant="outlined">
                          DECLINED
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell
                        colSpan={2}
                        sx={{ fontSize: "16px" }}
                        align="center"
                      >
                        <Button color="error" variant="outlined">
                          EXPIRED
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceRequests;
