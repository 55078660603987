import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./pages.scss";

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import useUserStore from "../../services/userStore";
import { NGROK } from "../../APIs";
import { myLocalStorage } from "../../components/StorageHelper";
import SnackBarButton from "../components/SnackBarButton";
import CountdownTimer from "../components/CountdownTimer";

const TenantManagement = () => {
  const userEmail = useUserStore((state) => state.user.email);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);

  const [tenantList, setTenantList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantInfo, setTenantInfo] = useState(null);
  const [apiKeyLoading, setApiKeyLoading] = useState({
    tenant: null,
    loading: false,
  });

  const tenantsStorageKey = `get-all-domains?email=${userEmail}-30min`;

  const deleteTenant = (tenant_name) => {
    axios.delete(`${NGROK}/api/delete-tenant?name=${tenant_name}`);

    setTenantList((prev) => {
      return prev.filter((tenant) => tenant.tenantName !== tenant_name);
    });
    localStorage.removeItem(`get-all-domains?email=${userEmail}-30min`);
  };

  const deleteApiKey = async (tenant) => {
    setApiKeyLoading({ tenant: tenant.tenantName, loading: true });
    await axios.delete(
      `${NGROK}/api/${tenant.tenantName}/tenant-admin/delete-api-key/${tenant.apiKey.key}?email=${tenant.email}`
    );

    localStorage.removeItem(`get-all-domains?email=${userEmail}-30min`);

    setTenantList(
      tenantList.map((el) => {
        if (el.domainName === tenant.domainName) {
          return {
            ...el,
            apiKey: { key: "", expiredAt: "" },
          };
        } else {
          return el;
        }
      })
    );
    setApiKeyLoading({ tenant: tenant.tenantName, loading: false });
  };

  const generateApiKey = async (tenant) => {
    if (tenant) {
      setApiKeyLoading({ tenant: tenant.tenantName, loading: true });
      const res = await axios.get(
        `${NGROK}/api/${tenant.tenantName}/tenant-admin/get-api-key?email=${tenant.email}`
      );

      localStorage.removeItem(`get-all-domains?email=${userEmail}-30min`);

      setTenantList(
        tenantList.map((el) => {
          if (el.domainName === tenant.domainName) {
            return {
              ...el,
              apiKey: { key: res.data.key, expiredAt: res.data.expiredAt },
            };
          } else {
            return el;
          }
        })
      );
      setApiKeyLoading({ tenant: tenant.tenantName, loading: false });
    }
  };

  const findTenant = (e) => {
    setSearchValue(e.target.value);
  };

  const getTenantInfo = async (tenant, e) => {
    if (e.target.tagName === "TD") {
      const response = await axios.get(
        `${NGROK}/api/${tenant.tenantName}/get-domain-info?email=${tenant.email}`
      );
      setTenantInfo(response.data);
      setSelectedTenantName(tenant.tenantName);
      console.log(response.data, "tenantInfo");
    }
  };

  const fetchTenants = async () => {
    const response = await axios.get(
      `${NGROK}/api/get-all-domains?email=${userEmail}`
    );
    setTenantList(response.data);
    myLocalStorage.setItem(tenantsStorageKey, response.data, 30);
  };

  useEffect(() => {
    const storageDomains = myLocalStorage.getItem(tenantsStorageKey);

    if (storageDomains && storageDomains.length && isCachingEnabled) {
      setTenantList([]);
      setTimeout(() => {
        setTenantList(storageDomains);
      }, 500);
    } else {
      fetchTenants();
    }
  }, [userEmail]);

  console.log(tenantList, "lsit");

  return (
    <>
      <Stack
        spacing={2}
        direction={"row"}
        display={"flex"}
        alignItems={"center"}
      >
        <TextField
          sx={{ width: "30%", margin: "10px 0 10px 0" }}
          label="Search the Tenant..."
          id="searchForTenant"
          onChange={findTenant}
        />
        <CreateTenantDialog fetchTenants={fetchTenants} />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <TableContainer
          sx={{ minWidth: "900px", maxWidth: "80%" }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white", fontSize: "16px" }}>
                  Tenant
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Number of computers
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Number of users
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Api key
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Api key Management
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Tenant Management
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantList
                .filter((tenant) => {
                  if (searchValue === "") {
                    return tenant;
                  } else if (
                    tenant.tenantName
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  ) {
                    return tenant;
                  }
                })
                .map((tenant) => (
                  <TableRow
                    key={tenant.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={(e) => getTenantInfo(tenant, e)}
                    className={
                      tenant.tenantName === selectedTenantName
                        ? "tenantRow activeTenantRow"
                        : "tenantRow"
                    }
                  >
                    <TableCell sx={{ fontSize: "16px" }}>
                      {tenant.tenantName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {tenant.numberOfComputers}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {tenant.numberOfUsers}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <SnackBarButton
                        selectedTenantName={selectedTenantName}
                        generateApiKey={generateApiKey}
                        tenant={tenant}
                        apiKey={tenant.apiKey.key}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <CountdownTimer
                        setTenantList={setTenantList}
                        tenantList={tenantList}
                        selectedTenantName={selectedTenantName}
                        deleteApiKey={deleteApiKey}
                        tenant={tenant}
                        apiKeyLoading={apiKeyLoading}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Button
                        disabled={!tenant.apiKey.key}
                        onClick={() => deleteApiKey(tenant)}
                        sx={
                          tenant.tenantName === selectedTenantName
                            ? { color: "white" }
                            : {}
                        }
                        variant="outlined"
                        color={"error"}
                      >
                        Delete API Key
                      </Button>
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Button
                        sx={
                          tenant.tenantName === selectedTenantName
                            ? { color: "white" }
                            : {}
                        }
                        onClick={() => deleteTenant(tenant.tenantName)}
                        variant="outlined"
                        color={"error"}
                      >
                        Delete Tenant
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedTenantName ? (
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "white",
              maxHeight: "725px",
              position: "sticky",
              top: 0,
              minWidth: "300px",
              overflow: "hidden",
            }}
          >
            <Table aria-label="table">
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "white", fontSize: "16px" }}
                  >
                    Tenant Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(tenantInfo).map(
                  ([key, value]) =>
                    key !== "domainName" &&
                    key !== "tenantName" &&
                    key !== "bindPassword" &&
                    key !== "domainPasswordProtect" &&
                    key !== "domainPassword" && (
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>{key}:</TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          {value}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Stack>
    </>
  );
};

const CreateTenantDialog = ({ fetchTenants }) => {
  const { email: userEmail } = useUserStore((state) => state.user);
  const setApiKey = useUserStore((state) => state.setApiKey);

  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [serverData, setServerData] = useState({
    tenantName: "",
    domainName: "",
    ldapServer: "",
    port: "",
    searchBase: "",
    server: "",
    bindDN: "",
    bindPassword: "",
    domainUser: "",
    domainPassword: "",
    policy: "",
    domainUserProtect: "",
    domainPasswordProtect: "",
    domainPolicyProtect: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckbox = () => {
    if (!isChecked) {
      setServerData({ ...serverData, domainName: serverData.tenantName });
    } else {
      setServerData({ ...serverData, domainName: serverData.domainName });
    }
  };

  const handleInputChange = (e) => {
    if (isChecked) {
      setServerData({
        ...serverData,
        [e.target.name]: e.target.value,
        domainName: e.target.value,
      });
    } else {
      setServerData({ ...serverData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${NGROK}/api/domain-info`, {
        ...serverData,
        email: userEmail,
      });

      setApiKey(response.data.key);
      fetchTenants();
    } catch {
      console.log("error duting sending server data");
    } finally {
      handleClose();
    }
  };

  console.log(serverData, "serverData");
  return (
    <>
      <Button
        sx={{ height: "55px" }}
        size="large"
        variant={"outlined"}
        onClick={handleClickOpen}
      >
        Create new tenant
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Create new tenant</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter tenant/domain name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tenant Name"
            type="text"
            fullWidth
            variant="outlined"
            name="tenantName"
            value={serverData.tenantName}
            onChange={(e) => handleInputChange(e)}
          />
          <Stack direction={"row"} spacing={3}>
            <TextField
              disabled={isChecked}
              autoFocus
              margin="dense"
              id="name"
              label="Domain Name"
              type="text"
              fullWidth
              variant="outlined"
              name="domainName"
              value={serverData.domainName}
              onChange={(e) => handleInputChange(e)}
            />
            <FormControlLabel
              sx={{ minWidth: "190px" }}
              label={"Same as Tenant Name"}
              control={
                <Checkbox
                  value={isChecked}
                  onClick={(e) => {
                    handleCheckbox();
                    setIsChecked(!isChecked);
                  }}
                  aria-label="Checkbox demo"
                  defaultChecked
                />
              }
            ></FormControlLabel>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!serverData.tenantName}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantManagement;
