import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";
import axios from "axios";

export const postLastSession = async ({
  email = null,
  latestTenant = null,
  latestComputerId = null,
  latestUserId = null,
  resourceId = null,
  latestPublisherId = null,
  latestAppId = null,
  latestFolderId = null,
  latestGroupId = null,
  latestTenantMode = null,
  latestUsername = null,
}) => {
  const response = await axios.post(`${NGROK}/api/latest-checking`, {
    email,
    latestTenant,
    latestComputerId,
    latestUserId,
    resourceId,
    latestPublisherId,
    latestAppId,
    latestFolderId,
    latestGroupId,
    latestTenantMode,
    latestUsername,
  });
  myLocalStorage.setItem(`${email}-lastSession`, response.data);
  return response.data;
};

export const getLastSession = async (userEmail) => {
  const lastSession = myLocalStorage.getItem(`${userEmail}-lastSession`);
  if (lastSession && Object.keys(lastSession)?.length) return lastSession;
  else {
    const response = await axios.get(
      `${NGROK}/api/latest-checking?email=${userEmail}`
    );
    myLocalStorage.setItem(`${userEmail}-lastSession`, response.data);
    return response.data;
  }
};

export const initialCategories = {
  appCategories: [
    { name: "Malware" },
    { name: "Pre-existing" },
    { name: "New" },
  ],
  folderCategories: [{ name: "Pre-existing" }, { name: "New" }],
  privilegeCategories: [
    { name: "Pre-existing" },
    { name: "New" },
    { name: "Sensitive" },
  ],
  publisherCategories: [
    { name: "Pre-existing" },
    { name: "New" },
    { name: "OS provider" },
    { name: "Well known" },
    { name: "Banned" },
  ],
};

export const sortOrderLinuxProfiles = [
  "linux_critical",
  "linux_secure",
  "linux_normal",
  "linux_original",
];
export const sortOrderWindowsProfiles = [
  "windows_critical",
  "windows_secure",
  "windows_normal",
  "windows_original",
];

export const resourceTypeListForCategoriesPage = [
  {
    name: "App",
    id: 1,
  },
  {
    name: "Folder",
    id: 2,
  },
  {
    name: "Group",
    id: 3,
  },
  {
    name: "Publisher",
    id: 4,
  },
  {
    name: "Url",
    id: 5,
  },
];

export const generalProfileOrder = ["critical", "secure", "normal", "original"];

export const getResourceNameField = (resourceType) => {
  const resourceNameField =
    resourceType === "App" || resourceType === "Folder"
      ? "path"
      : resourceType === "Group"
      ? "cn"
      : resourceType === "Publisher"
      ? "friendlyName"
      : "name";
  return resourceNameField;
};

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};


