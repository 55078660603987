import React, { useState, useRef, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { NGROK } from "../../APIs";
import axios from "axios";

const CountdownTimer = ({
  deleteApiKey,
  tenant,
  selectedTenantName,
  setTenantList,
  tenantList,
  apiKeyLoading,
}) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const [isInputTimeOpen, setIsInputTimeOpen] = useState(false);
  const [newDownTime, setNewDownTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  let interval = useRef();

  const startTimer = (countdownDate) => {
    const now = Date.now();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance <= 0) {
      deleteApiKey(tenant);
      clearInterval(interval.current);
    } else {
      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    }
  };

  const submitNewExpiredTime = async () => {
    if (newDownTime > 168 || newDownTime < 0.1) {
      setErrorMessage("Choose time between 1 and 168 hours.");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    } else {
      const newExpiredAt = new Date(
        Date.now() +
          (newDownTime * 60 * 60 - new Date().getTimezoneOffset() * 60) * 1000
      ).toISOString();

      const response = await axios.put(
        `${NGROK}/api/${tenant.tenantName}/tenant-admin/update-api-key`,
        {
          email: tenant.email,
          expiredAt: newExpiredAt,
          key: tenant.apiKey.key.toString(),
        }
      );

      const indexOfChangedTenant = tenantList.findIndex(
        (el) => tenant.domainName === el.domainName
      );
      const newTenants = [...tenantList];
      newTenants.splice(indexOfChangedTenant, 1, {
        ...tenant,
        apiKey: { key: response.data.key, expiredAt: response.data.expiredAt },
      });
      setTenantList(newTenants);

      setIsInputTimeOpen(false);
    }
  };

  useEffect(() => {
    const localTimer = tenant.apiKey.expiredAt
      ? Date.parse(tenant.apiKey.expiredAt)
      : null;
    if (localTimer) {
      interval.current = setInterval(() => {
        startTimer(+localTimer);
      }, 1000);
    }

    return () => clearInterval(interval.current);
  }, [tenantList]);
  
  return (
    <>
      {tenant.apiKey.key && !isInputTimeOpen && !apiKeyLoading.loading && (
        <Button
          onClick={() => setIsInputTimeOpen(true)}
          variant="outlined"
          sx={
            tenant.tenantName === selectedTenantName
              ? {
                  padding: "0px 10px 0px 10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "130px",
                  color: "white",
                }
              : {
                  padding: "0px 10px 0px 10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "130px",
                }
          }
        >
          <Typography textAlign={"center"}>Expire Time</Typography>
          <Stack
            height={20}
            sx={{ alignItems: "center" }}
            spacing={2}
            direction={"row"}
          >
            <Box>
              <p>{timerDays}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerHours}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerMinutes}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerSeconds}</p>
            </Box>
          </Stack>
        </Button>
      )}
      {isInputTimeOpen && !apiKeyLoading.loading && (
        <TextField
          sx={{ width: "120px", input: { color: "white" } }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              submitNewExpiredTime();
            }
          }}
          placeholder="Enter hours"
          onChange={(e) => {
            setNewDownTime(e.target.value);
          }}
          type="number"
        />
      )}
      {apiKeyLoading.loading && apiKeyLoading.tenant === tenant.tenantName ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
    </>
  );
};

export default CountdownTimer;
