import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  Divider,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { myLocalStorage } from "../../../components/StorageHelper";

import Timer from "../../components/Timer";

import ".//../pages.scss";
import useUserStore from "../../../services/userStore";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import StyledTooltip from "../../components/StyledTooltip";
import MuiAlert from "@mui/material/Alert";
import ResourceRequestForm from "../../components/ResourceRequestForm";

const ApplicationsControl = ({
  applications,
  setApplications,
  publishers,
  selectedTenantName,
  selectedUser,
  userData,
  showCount,
  setShowCount,
}) => {
  const appRef = useRef();
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);

  const [appsSearchValues, setAppsSearchValues] = useState("");
  const [filterAppsValue, setFilterAppsValue] = useState("Show All");
  const [publisherSearchValues, setPublisherSearchValues] = useState("");
  const [selectedPublisher, setSelectedPublisher] = useState(undefined);
  const [hideEmptyPublishers, setHideEmptyPublishers] = useState(true);

  const [unsignedAppsPublisher, setUnsignedAppsPublisher] = useState(null);
  const [allAppsPublisher, setAllAppsPublisher] = useState({
    id: "allAppsPublisher",
    frinedlyName: "allAppsPublisher",
  });

  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);
  const resourcesDefaultTime = useUserStore(
    (state) => state.resourcesDefaultTime
  );
  const activeComputer = useUserStore((state) => state.activeComputer);

  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackbarSettings;

  const filterPublishers = (publishers) => {
    if (publishers?.length) {
      const PublishersWithoutDuplicates = publishers
        .map((publisher) => ({ ...publisher, ids: [publisher.id] }))
        .reduce((acc, current) => {
          const x = acc.find(
            (publisher) => publisher.friendlyName === current.friendlyName
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            x.ids.push(current.id);
            return acc;
          }
        }, []);
      return hideEmptyPublishers
        ? PublishersWithoutDuplicates.filter((publisher) =>
            publisher.containsApps ? publisher : null
          )
        : PublishersWithoutDuplicates;
    }
  };

  const getApplications = async (publisherIds, publisherName, id) => {
    setApplications(undefined);
    setAppsSearchValues("");

    const storageKey = `${selectedTenantName}/computer-users/${selectedUser.id}/${publisherName}-30min`;
    const storageApps = myLocalStorage.getItem(storageKey);

    if (storageApps && storageApps.length && isCachingEnabled) {
      setTimeout(() => {
        setApplications(storageApps);
      }, 500);
    } else {
      const appArr = [];

      const getUrl = (publisherId) =>
        `${NGROK}/api/${selectedTenantName}/computer-users/${selectedUser.id}/publishers/${publisherId}/apps`;

      const fetchData = async (url) => {
        const result = await axios.get(url);
        if (result.data) appArr.push(result.data.content);
      };

      if (publisherName !== "Unsigned Apps") {
        await Promise.all(
          publisherIds.map((publisherId) => fetchData(getUrl(publisherId)))
        );
      } else {
        await fetchData(getUrl(id));
      }

      setApplications(appArr.flat());

      if (appArr.length) {
        myLocalStorage.setItem(storageKey, appArr.flat(), 30);
      }
    }
  };

  const changeApplicationStatus = (newApp) => {
    setApplications(
      applications.map((el) => (el.appId === newApp.appId ? newApp : el))
    );
  };

  const addUserToApplication = async (application, groupStatus) => {
    const profileName = activeComputer.profile;
    const timeInSeconds = resourcesDefaultTime[profileName] * 60;
    const storageKey = `${selectedTenantName}/computer-users/${selectedUser.id}/${selectedPublisher.friendlyName}-30min`;

    changeApplicationStatus({ ...application, groupStatus });
    try {
      const response = await axios.put(
        `${NGROK}/api/${selectedTenantName}/computer-user/publishers/apps/timer`,
        {
          userId: application.computerUserId,
          publisherId: application.publisherId,
          appId: application.appId,
          email: userData.email,
          role: userData.role,
          groupStatus,
          selectedTime: timeInSeconds,
        }
      );

      if (
        groupStatus === "INHERITED" &&
        response.data.inheritedGroupStatus === null
      ) {
        setSnackbarSettings((prev) => ({ ...prev, open: true }));
        setTimeout(() => {
          setSnackbarSettings((prev) => ({ ...prev, open: false }));
        }, 4000);
      }
      localStorage.removeItem(storageKey);
      changeApplicationStatus(response.data);
    } catch (error) {
      changeApplicationStatus(application);
    }
  };

  const findApplication = (e) => {
    setTimeout(() => {
      setAppsSearchValues(e.target.value);
    }, 1500);
  };

  const findPublisher = (e) => {
    setTimeout(() => {
      setPublisherSearchValues(e.target.value);
    }, 1500);
  };

  const filteredApplications =
    applications?.length &&
    applications
      .filter((application) => {
        if (appsSearchValues === "") {
          return application;
        } else if (
          application.appName
            .toLowerCase()
            .includes(appsSearchValues.toLowerCase())
        ) {
          return application;
        }
      })
      .filter((application) => {
        if (filterAppsValue === "Show All") return application;
        else if (filterAppsValue === "Show Yes only")
          return (
            application.groupStatus === "ENABLED" ||
            application.inheritedGroupStatus === "ENABLED"
          );
        else if (filterAppsValue === "Show No only")
          return (
            application.groupStatus === "DISABLED" ||
            application.inheritedGroupStatus === "DISABLED"
          );
        else if (filterAppsValue === "Show Dynamic only")
          return (
            application.groupStatus === "DYNAMIC" ||
            application.inheritedGroupStatus === "DYNAMIC"
          );
      });

  const filteredPublishers =
    filterPublishers(publishers)?.length &&
    filterPublishers(publishers).filter((publisher) => {
      if (publisherSearchValues === "") {
        return publisher.friendlyName !== "Unsigned Apps";
      } else if (
        publisher.friendlyName
          .toLowerCase()
          .includes(publisherSearchValues.toLowerCase())
      ) {
        return publisher.friendlyName !== "Unsigned Apps";
      }
    });

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const getInheritedGroupStatusValue = (app) => {
    if (app.inheritedGroupStatus === "ENABLED") {
      return "(YES)";
    } else if (app.inheritedGroupStatus === "DISABLED") {
      return "(NO)";
    } else if (app.inheritedGroupStatus === "DYNAMIC") {
      return "(DYNAMIC)";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (publishers?.length) {
      const publisher = filterPublishers(publishers).find(
        (publisher) => publisher.id === selectedPublisher?.id
      );
      if (publisher) setSelectedPublisher(publisher);
      else {
        const publisher = filterPublishers(publishers)[0];
        if (publisher) setSelectedPublisher(publisher);
      }
    }
  }, [publishers]);

  useEffect(() => {
    const unsignedAppsPublisher = publishers?.find(
      (el) => el.friendlyName === "Unsigned Apps"
    );
    setUnsignedAppsPublisher(unsignedAppsPublisher);
  }, [publishers]);

  const getAllApplications = async () => {
    const response = await axios.get(
      `${NGROK}/api/computer-user/get-all-apps?userId=${selectedUser.id}&computerId=${activeComputer.id}`
    );

    setApplications(response.data?.content);
  };

  useEffect(() => {
    if (
      selectedPublisher !== undefined &&
      selectedPublisher?.id !== "allAppsPublisher"
    )
      getApplications(
        selectedPublisher.ids,
        selectedPublisher.friendlyName,
        selectedPublisher.id
      );
    setShowCount(50);
  }, [selectedPublisher]);

  console.log(filterAppsValue, "filterAppsValue")

  return (
    <section className=" applicationTab">
      <>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={() =>
            setSnackbarSettings({ ...snackbarSettings, open: false })
          }
          message="You can't make this resource inherited"
          key={vertical + horizontal}
        >
          <MuiAlert
            sx={{ fontSize: "18px", fontWeight: "500" }}
            severity="warning"
          >
            You can't make this resource inherited
          </MuiAlert>
        </Snackbar>
        {publishers?.length ? (
          <TableContainer
            style={{ height: "fit-content", minWidth: 250 }}
            component={Paper}
          >
            <Table
              sx={{
                height: "fit-content",
                wordWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className="userName">
                    Publishers and App Group
                  </TableCell>
                </TableRow>
                {filterPublishers(publishers).length > 10 ? (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        sx={{ minWidth: "200px" }}
                        label="Find publisher ..."
                        id="searchForPublisher"
                        onChange={findPublisher}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={hideEmptyPublishers}
                    onChange={(e) => setHideEmptyPublishers(e.target.checked)}
                  />
                  <Typography fontSize={19}>Hide empty publishers</Typography>
                </TableRow>
                <TableRow
                  className={
                    allAppsPublisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    setSelectedPublisher(allAppsPublisher);
                    getAllApplications();
                  }}
                >
                  <TableCell
                    scope="row"
                    className="userName"
                    component="td"
                    sx={{ marginBottom: "30px" }}
                  >
                    All Apps
                  </TableCell>
                </TableRow>
                {unsignedAppsPublisher ? (
                  <TableRow
                    className={
                      unsignedAppsPublisher.id === selectedPublisher?.id
                        ? "userRow activeUserRow"
                        : "userRow"
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      setSelectedPublisher(unsignedAppsPublisher);
                      setLastSession({
                        ...lastSession,
                        latestPublisherId: unsignedAppsPublisher.id,
                      });
                    }}
                  >
                    <TableCell
                      scope="row"
                      className="userName"
                      component="td"
                      sx={{ marginBottom: "30px" }}
                    >
                      Unsigned Apps
                    </TableCell>
                  </TableRow>
                ) : null}
                <Divider
                  sx={{
                    margin: "10px auto",
                    width: "150px",
                  }}
                />
                {filteredPublishers.map((publisher) => (
                  <TableRow
                    key={publisher.id}
                    className={
                      publisher.id === selectedPublisher?.id
                        ? "userRow activeUserRow"
                        : "userRow"
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      setSelectedPublisher(publisher);
                      setLastSession({
                        ...lastSession,
                        latestPublisherId: publisher.id,
                      });
                    }}
                  >
                    <StyledTooltip
                      arrow
                      title={
                        <>
                          <Typography color="inherit">
                            {`Subject: ${publisher.subject}`}
                          </Typography>
                          <br />
                          <Typography color="inherit">
                            {`Thumbprint: ${publisher.thumbprint}`}
                          </Typography>
                        </>
                      }
                      placement="left"
                    >
                      <TableCell
                        scope="row"
                        className="groupRowName userName"
                        component="td"
                      >
                        <>{publisher.friendlyName}</>
                      </TableCell>
                    </StyledTooltip>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : publishers === undefined ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              There are no any publishers.
            </p>
          </Box>
        )}

        {applications !== undefined && applications.length ? (
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"column"} spacing={2} sx={{ padding: "15px" }}>
              <RadioAuthFilter
                filterResourceValue={filterAppsValue}
                filterName={"Filter by privilege"}
                handleFilterResourceValue={handleFilterResourceValue}
              />
              {applications?.length > 10 ? (
                <TextField
                  fullWidth
                  sx={{ minWidth: "200px" }}
                  label="Search the Applications..."
                  id="searchForApplication"
                  onChange={findApplication}
                />
              ) : null}
            </Stack>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  borderCollapse: "collapse",
                  borderStyle: "hidden",
                  width: "100%",
                  height: "fit-content",
                  "& td, & th": {
                    border: "1px solid #233044",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow ref={appRef}>
                    <TableCell className="userName">Application</TableCell>
                    <TableCell className="userName">Privilege Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredApplications
                    .slice(0, showCount)
                    .map((application) => (
                      <TableRow className="groupRow" key={application.appId}>
                        <StyledTooltip
                          arrow
                          title={
                            <>
                              <Typography color="inherit">
                                {`Path: ${application.path}`}
                              </Typography>
                              <br />
                              <Typography color="inherit">
                                {`Size: ${application.size}`}
                              </Typography>
                              <br />
                              <Typography color="inherit">
                                {`Fingerprint: ${application.fingerprintSha256}`}
                              </Typography>
                            </>
                          }
                          placement="left"
                        >
                          <TableCell className="userName">
                            {application.appName}
                          </TableCell>
                        </StyledTooltip>
                        <TableCell className="actionBlock">
                          <Stack direction={"row"} spacing={3}>
                            <Select
                              disabled={userData.role === "TENANT_USER"}
                              sx={{ minWidth: "100px" }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={
                                application.groupStatus
                                  ? application.groupStatus
                                  : ""
                              }
                              size="small"
                              onChange={(event) =>
                                addUserToApplication(
                                  application,
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value={"ENABLED"}>YES</MenuItem>
                              <MenuItem value={"DISABLED"}>NO</MenuItem>
                              <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                              <MenuItem value={"INHERITED"}>
                                INHERITED{" "}
                                {getInheritedGroupStatusValue(application)}
                              </MenuItem>
                            </Select>
                            {application.groupStatus === "DYNAMIC" &&
                            application.remainingTime !== 0 ? (
                              <Stack spacing={1} sx={{ width: 150 }}>
                                <Timer minutes={application.remainingTime} />
                              </Stack>
                            ) : null}
                            {(application.groupStatus === "DISABLED" ||
                              application.inheritedGroupStatus === "DISABLED" ||
                              (application.inheritedGroupStatus === "DYNAMIC" &&
                                application.remainingTime === 0) ||
                              (application.groupStatus === "DYNAMIC" &&
                                application.remainingTime === 0)) &&
                            userData.role === "TENANT_USER" ? (
                              <ResourceRequestForm
                                publisherId={application.publisherId}
                                tenantName={selectedTenantName}
                                resourceName={application.appName}
                                selectedUser={selectedUser}
                                resourceId={application.appId}
                                resourceType={"APP"}
                                computer={activeComputer}
                              />
                            ) : null}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  {applications?.length > showCount ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Button
                          variant={"text"}
                          onClick={() =>
                            setShowCount((prevCount) => prevCount + 50)
                          }
                        >
                          Load more...
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        ) : applications === undefined && selectedPublisher?.id ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              Publisher doesn't have any applications.
            </p>
          </Box>
        )}
      </>
    </section>
  );
};

export default ApplicationsControl;
