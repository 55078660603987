import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import RadioFilter from "./components/RadioFilter";
import { getResourceNameField } from "../../../services/Helpers";

const ResourcesTable = ({
  resourceType,
  os,
  selectedCategory,
  allSelectedResources,
  setAllSelectedResources,
  fetchResources,
  filterResourceValue, 
  handleFilterResourceValue
}) => {
  const userEmail = useUserStore((state) => state.user.email);

  const [resourceSearchValues, setResourceSearchValues] = useState("");

  const resourceNameField = getResourceNameField(resourceType);

  const [loading, setIsloading] = useState(false);

  const typeOfUpdatedResourceForApi =
    resourceType === "App"
      ? "apps"
      : resourceType === "Folder"
      ? "folders"
      : resourceType === "Group"
      ? "groups"
      : resourceType === "Publisher"
      ? "publishers"
      : "url-control";

  const selectedResources =
    allSelectedResources?.length &&
    allSelectedResources
      .filter((resource) => {
        if (resourceSearchValues === "") {
          return resource;
        } else if (
          resource[
            resourceType === "App" || resourceType === "Folder"
              ? "path"
              : resourceType === "Group"
              ? "cn"
              : resourceType === "Publisher"
              ? "friendlyName"
              : "name"
          ]
            .toLowerCase()
            .includes(resourceSearchValues.toLowerCase())
        ) {
          return resource;
        }
      })
      .filter((resource) => {
        if (filterResourceValue === "Show All") return resource;
        else if (filterResourceValue === "Show unselected")
          return !resource.categories.includes(selectedCategory?.name);
        else {
          return resource.categories.includes(selectedCategory?.name);
        }
      });

  const findResourceByName = (e) => {
    setTimeout(() => {
      setResourceSearchValues(e.target.value);
    }, 1500);
  };

  const connectCategoryToResource = async (resourceCategoryName, resource) => {
    if (resource.categories.includes(resourceCategoryName)) return;

    const resourceName =
      resourceType === "App"
        ? resource.path
        : resourceType === "Folder"
        ? resource.path
        : resourceType === "Group"
        ? resource.cn
        : resourceType === "Publisher"
        ? resource.friendlyName
        : resource.name;

    try {
      await axios.post(`${NGROK}/api/category/${typeOfUpdatedResourceForApi}`, {
        operationSystem: os,
        resourceCategory: resourceCategoryName,
        resourceId: resource.id,
        resourceName,
      });

      //set inclusions
      const inclusions = selectedCategory?.manualInclusions.includes(
        resourceName
      )
        ? selectedCategory?.manualInclusions
        : [...selectedCategory?.manualInclusions, resourceName];
      updateCategory(selectedCategory, inclusions);
      //

      const newResources = allSelectedResources.map((el) =>
        el.id === resource.id
          ? { ...el, categories: [...el.categories, resourceCategoryName] }
          : el
      );
      setAllSelectedResources(newResources);
    } catch (error) {
      console.log(error, "eeror during add category to resource");
    }
  };

  const updateCategory = async (category, inclusions) => {
    await axios.put(`${NGROK}/api/category`, {
      newName: category.name,
      reputationScore: category.reputationScore,
      oldName: category.name,
      oldSearchPatterns: category.searchPatterns,
      os,
      resourceType: resourceType.toUpperCase(),
      profiles: category.profiles,
      userEmail,
      manualExclusions: category.manualExclusions,
      manualInclusions: inclusions,
    });
  };

  const deleteCategoryFromResource = async (categoryName, resource) => {
    if (!resource.categories.includes(categoryName)) return;

    try {
      await axios.delete(
        `${NGROK}/api/category/${categoryName}/${typeOfUpdatedResourceForApi}/${resource.id}`
      );

      const newResources = allSelectedResources
        .map((selectedResource) =>
          selectedResource.id === resource.id
            ? {
                ...selectedResource,
                categories: selectedResource.categories.filter(
                  (category) => category !== categoryName
                ),
              }
            : selectedResource
        )
        .sort((a, b) => a.id - b.id);

      setAllSelectedResources(newResources);
    } catch (error) {
      console.log(error, "eeror during deleting category");
    }
  };

  useEffect(() => {
    fetchResources(os);
  }, [os, selectedCategory, resourceType]);

  console.log(allSelectedResources, "allSelectedResources");

  return (
    <Stack spacing={2}>
      <TableContainer
        component={Paper}
        sx={{
          border: 1,
          minWidth: 450,
          maxWidth: 1200,
          marginTop: 5,
          height: "fit-content",
        }}
      >
        <Table
          aria-label="simple table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            width: "100%",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 80 }} align="center">
                <Typography fontSize={17} fontWeight={600}>
                  Sl No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography fontSize={17} fontWeight={600}>
                  {resourceType}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: 170,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                align="center"
              >
                <Typography fontSize={17} fontWeight={600}>
                  Management
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedResources?.length
              ? selectedResources.map((resource, i) => (
                  <TableRow key={resource.id}>
                    <TableCell component="th" scope="row" align="center">
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 300,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {resource[resourceNameField]}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {resource.categories.includes(selectedCategory?.name) ? (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() =>
                            deleteCategoryFromResource(
                              selectedCategory?.name,
                              resource
                            )
                          }
                        >
                          Delete
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            connectCategoryToResource(
                              selectedCategory?.name,
                              resource
                            )
                          }
                          disabled={!selectedCategory}
                        >
                          Add to category
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ResourcesTable;
