import React, { useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { NGROK } from "../../../../APIs";
import useAxios from "../../../../hooks/useAxios";
import useUserStore from "../../../../services/userStore";

const TenantSelection = ({ selectedTenant, setSelectedTenant }) => {
  const userData = useUserStore((state) => state.user);

  const {
    data: tenantsList,
    error,
    loading,
  } = useAxios(`${NGROK}/api/get-all-domains?email=${userData.email}`);

  useEffect(() => {
    if (tenantsList) setSelectedTenant(tenantsList[0]);
  }, [tenantsList]);
  return (
    <Box>
      {tenantsList && selectedTenant ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenant.tenantName}
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{
            width: 300,
            maxHeight: 50,
            backgroundColor: "white",
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedTenant(null);
            }
            const tenant = tenantsList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setSelectedTenant(tenant);
            }
          }}
        />
      ) : null}
    </Box>
  );
};

export default TenantSelection;
