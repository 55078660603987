import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Stack,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Chip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { NGROK } from "../../../../APIs";
import MatchedResourceTable from "./MatchedResourceTable";
import ProfilesList from "./ProfileList";
import useUserStore from "../../../../services/userStore";
import { getResourceNameField } from "../../../../services/Helpers";

const EditCategoryDialog = ({
  allSelectedResources,
  category,
  resourceType,
  os,
  fetchResources,
  fetchCategories,
  selectedTenantName,
  selectedCategory,
}) => {
  const { email: userEmail } = useUserStore((state) => state.user);

  const [newCategory, setNewCategory] = useState("");
  const [categoryPriority, setCategoryPriority] = useState(0);
  const [matchedResources, setMatchedResources] = useState([]);
  const [searchPatternList, setSearchPatternList] = useState([]);
  const [patternInputText, setPatternInputText] = useState("");
  const [dialogToShow, setDialogToShow] = useState(null);
  const [profilesWithTrustLevels, setProfilesWithTrustLevels] = useState(
    category.profiles
  );
  const [manualExclusions, setManualExclusions] = useState(
    category.manualExclusions
  );

  const resourceNameField = getResourceNameField(resourceType);

  const checkMatches = (newPattern) => {
    const filteredResources = allSelectedResources.filter((resource) =>
      resource[resourceNameField].match(newPattern)
    );
    setMatchedResources(filteredResources);
  };

  const handleDeletePattern = (id) => {
    if (searchPatternList.length <= 1) return;

    const updatedPatternList = searchPatternList.filter(
      (field) => field.id !== id
    );
    setSearchPatternList(updatedPatternList);
  };

  const addPatternToCategory = (inputText) => {
    const updatedPatternList = [
      ...searchPatternList,
      { id: uuidv4(), text: inputText },
    ];
    setSearchPatternList(updatedPatternList);
    setPatternInputText("");
  };

  const closeAndCleanDialog = () => {
    setDialogToShow(null);
    setMatchedResources([]);
    setSearchPatternList([]);
  };

  const updateCategory = async () => {
    await axios.put(`${NGROK}/api/category`, {
      newName: newCategory,
      reputationScore: categoryPriority,
      newSearchPatterns: searchPatternList,
      oldName: category.name,
      oldSearchPatterns: category.searchPatterns,
      os,
      resourceType: resourceType.toUpperCase(),
      profiles: profilesWithTrustLevels,
      userEmail,
      manualExclusions,
    });
  };

  const getAllMatchedResources = (newPatterns) => {
    return allSelectedResources.filter((resource) =>
      newPatterns.some((pattern) =>
        resource[resourceNameField].match(pattern.text)
      )
    );
  };

  const handleSubmit = () => {
    updateCategory();

    setTimeout(() => {
      fetchCategories(os);
      fetchResources(os);
    }, 1000);
    closeAndCleanDialog();
  };

  useEffect(() => {
    if (category.hasOwnProperty("searchPatterns")) {
      const oldPatterns = category.searchPatterns.map((pattern) => ({
        id: uuidv4(),
        text: pattern,
      }));
      setSearchPatternList(oldPatterns);
    } else {
      setSearchPatternList([]);
    }
  }, [dialogToShow]);

  useEffect(() => {
    if (category.hasOwnProperty("name")) {
      setNewCategory(category.name);
    }
  }, []);

  console.log(category, "category");
  return (
    <>
      <Button
        sx={category.name === selectedCategory?.name ? { color: "white" } : {}}
        variant="outlined"
        color="primary"
        onClick={() => {
          setDialogToShow(category.name);
        }}
      >
        Edit
      </Button>
      <Dialog
        open={dialogToShow === category.name}
        onClose={closeAndCleanDialog}
        fullWidth
      >
        <DialogTitle>Fill required fields</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Box
              height={60}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <TextField
                required
                autoFocus
                id="name"
                label="Category Name"
                type="text"
                variant="outlined"
                defaultValue={category.name}
                onChange={(e) => setNewCategory(e.target.value)}
                sx={{ height: 45, width: "70%" }}
              />
              <TextField
                required
                autoFocus
                id="name"
                label="Priority"
                type="number"
                variant="outlined"
                defaultValue={category.reputationScore}
                onChange={(e) => setCategoryPriority(Number(e.target.value))}
                sx={{ height: 45, width: "25%" }}
              />
            </Box>
            <ProfilesList
              os={os}
              selectedTenantName={selectedTenantName}
              profilesWithTrustLevels={profilesWithTrustLevels}
              setProfilesWithTrustLevels={setProfilesWithTrustLevels}
            />

            <Box component={"fieldset"} borderRadius={1}>
              <legend>Resource Name/Pattern</legend>
              {searchPatternList.length ? (
                <Box
                  display={"inline-flex"}
                  flexWrap={"wrap"}
                  borderRadius={1}
                  // sx={{ backgroundColor: "#e6f2ff" }}
                >
                  {searchPatternList.map((textField) => (
                    <Chip
                      sx={{ margin: "4px" }}
                      key={textField.id}
                      variant="outlined"
                      color="primary"
                      label={textField.text}
                      onDelete={() => handleDeletePattern(textField.id)}
                      onClick={() => checkMatches(textField.text)}
                    />
                  ))}
                  <Button
                    sx={{ height: 31, margin: "4px" }}
                    variant="outlined"
                    onClick={() =>
                      setMatchedResources(
                        getAllMatchedResources(searchPatternList)
                      )
                    }
                  >
                    All matches
                  </Button>
                </Box>
              ) : null}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <TextField
                  sx={{ marginRight: "10px" }}
                  fullWidth
                  margin="dense"
                  id="name"
                  label="Pattern"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={patternInputText}
                  onChange={(e) => {
                    checkMatches(e.target.value);
                    setPatternInputText(e.target.value);
                  }}
                />
                <Button
                  disabled={!patternInputText || !matchedResources.length}
                  variant="outlined"
                  size={"small"}
                  // sx={{ height: "30px",  }}
                  onClick={(e) => addPatternToCategory(patternInputText)}
                >
                  Add pattern
                </Button>
              </Box>
            </Box>
            {matchedResources.length ? (
              <Box component={"fieldset"} borderRadius={1}>
                <legend>Matched Resources</legend>
                <MatchedResourceTable
                  manualExclusions={manualExclusions}
                  setManualExclusions={setManualExclusions}
                  matchedResources={matchedResources}
                  resourceNameField={resourceNameField}
                />
              </Box>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeAndCleanDialog}>
            Cancel
          </Button>
          <Button
            disabled={!newCategory}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCategoryDialog;
