import React, { useState, useEffect } from "react";

import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import useUserStore from "../services/userStore";

const BreadcrumbsNav = ({ page }) => {
  const setUserName = useUserStore((state) => state.setUserName);
  const lastSession = useUserStore((state) => state.lastSession);
  const setTenantName = useUserStore((state) => state.setTenantName);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const selectedProfile = useUserStore((state) => state.selectedProfile);
  const setSelectedProfile = useUserStore((state) => state.setSelectedProfile);

  const location = useLocation();

  const [computerName, setComputerName] = useState("");

  useEffect(() => {
    if (activeComputer) {
      setComputerName(
        activeComputer.cn
          ? activeComputer.cn
          : activeComputer.dNSHostName
          ? activeComputer.dNSHostName
          : activeComputer.domainName
      );
    }
  }, [activeComputer]);

  useEffect(() => {
    if (location.pathname !== "/endpoints/resources") {
      setComputerName("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== "/policyProfiles/resources") {
      setSelectedProfile(undefined);
    }
  }, [location.pathname]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ fontSize: "22px" }}
    >
      <Link
        style={{ color: "black" }}
        to={page === "endpoints" ? "/endpoints" : "/policyProfiles"}
        onClick={() => {
          setTenantName("");
          setUserName("");
          setComputerName("");
        }}
      >
        Tenants
      </Link>
      {lastSession.latestTenant && (
        <Typography fontSize={22} color="text.primary">
          {lastSession.latestTenant}
        </Typography>
      )}
      {page === "endpoints"
        ? lastSession.latestTenant &&
          activeComputer && (
            <Typography fontSize={22} color="text.primary">
              {computerName && computerName}
            </Typography>
          )
        : null}
      {page === "endpoints" && location.pathname === "/endpoints/resources" ? (
        <Typography fontSize={22} color="text.primary">
          Resources
        </Typography>
      ) : null}
      {page === "endpoints" && location.pathname === "/endpoints/sessions" ? (
        <Typography fontSize={22} color="text.primary">
          Sessions
        </Typography>
      ) : null}
      {page === "policyProfiles"
        ? lastSession.latestTenant &&
          selectedProfile && (
            <Typography fontSize={22} color="text.primary">
              {selectedProfile.name}
            </Typography>
          )
        : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
